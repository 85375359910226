import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./UserDetails.scss";
import { profileDetails } from "../../redux/actions/ProfileDetails";

const ProfileDetails = (props) => {
  useEffect(() => {
    props.profileDetails();
  }, []);
  useEffect(() => {
    if (props.apiData) {
      if (props.apiData.status === "UNAUTHORIZED") {
        props.ChangeActiveComp("login");
      }
    }
  }, [props.apiData]);

  return (
    <>
      <div className="continer-fluid text-left">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-6">
                <div className="profileDetails">
                  <ul className="leftUl">
                    <li>Name </li>
                    <li>Gender</li>
                    <li>Marital status</li>
                    <li>Mobile no </li>
                    <li>Email-Id </li>
                    <li>Date of birth</li>
                    <li>Pan number</li>
                    <li>
                      {props.docName === "VOTERID" ? "VoterID  " : "Aadhaar "}
                    </li>
                    <li>Profession </li>
                    <li>Education </li>
                    <li>Address</li>
                  </ul>
                </div>
              </div>
              <div className="col-6 text-left">
                <div className="profileDetails">
                  <ul className="rightUl">
                    <li>{props.name}</li>
                    <li>{props.gender}</li>
                    <li>{props.marital_status}</li>
                    <li>{props.mob_no}</li>
                    <li>{props.email}</li>
                    <li>{props.DoB}</li>
                    <li>
                      {props.pan && props.pan.replace(/\w(?=\w{4})/g, "*")}
                    </li>
                    <li>
                      {props.docName === "VOTERID"
                        ? `${
                            props.docValue &&
                            props.docValue.replace(/\w(?=\w{4})/g, "*")
                          }`
                        : `${props.docValue}`}
                    </li>
                    <li>{props.profession}</li>
                    <li>{props.education}</li>
                    <li>
                      {props.address} , {props.pincode}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => {
  return {
    apiData: state.ProfileDetails && state.ProfileDetails.data,
    name:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.pan_name,
    gender:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.gender,
    mob_no:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.mobile_phone_number,
    marital_status:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.marital_status,
    email:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.email_address,
    DoB:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.date_of_birth,
    pan:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.pan_number,
    profession:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.professionEntity &&
      state.ProfileDetails.data.results[0].properties.professionEntity
        .properties &&
      state.ProfileDetails.data.results[0].properties.professionEntity
        .properties.name,
    education:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.educationEntity &&
      state.ProfileDetails.data.results[0].properties.educationEntity
        .properties &&
      state.ProfileDetails.data.results[0].properties.educationEntity.properties
        .name,
    address:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.addressEntity &&
      state.ProfileDetails.data.results[0].properties.addressEntity
        .properties &&
      state.ProfileDetails.data.results[0].properties.addressEntity.properties
        .address,
    pincode:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.addressEntity &&
      state.ProfileDetails.data.results[0].properties.addressEntity
        .properties &&
      state.ProfileDetails.data.results[0].properties.addressEntity.properties
        .current_pin_code,
    //  docs data
    docName:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.docEntity &&
      state.ProfileDetails.data.results[0].properties.docEntity.properties &&
      state.ProfileDetails.data.results[0].properties.docEntity.properties.key,
    docValue:
      state.ProfileDetails.data &&
      state.ProfileDetails.data.results &&
      state.ProfileDetails.data.results[0].properties &&
      state.ProfileDetails.data.results[0].properties.docEntity &&
      state.ProfileDetails.data.results[0].properties.docEntity.properties &&
      state.ProfileDetails.data.results[0].properties.docEntity.properties
        .value,
  };
};
const mapDispatch = {
  profileDetails,
};

export default connect(mapState, mapDispatch)(ProfileDetails);
