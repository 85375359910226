import React from "react";
import "./ToolTip.scss";
import ReactTooltip from "react-tooltip";
import { ToolTipStyle } from "./InlineStyle";
import toolTip from "../../assets/img/ToolTip.svg";
function ToolTip({ content, tipIcon, stage }) {
  return (
    <div className={stage === 5 ? "active_home_loan_tooltip_style" : null}>
      <button
        data-tip={content}
        data-for={"tooltip"}
        data-event={"click"}
        className="rounded-tooltip"
      >
        <img
          src={toolTip}
          alt="tooltip"
          className={`tooltip-image ${tipIcon}`}
        />
      </button>

      <ReactTooltip
        effect="solid"
        place="bottom"
        id="tooltip"
        globalEventOff={"click"}
        multiline={true}
        backgroundColor={ToolTipStyle.background}
        textColor={ToolTipStyle.text}
        offset={{ left: 0, top: 3 }}
        className="tooltip-body tooltip-body-repayment"
      />
    </div>
  );
}

export default ToolTip;
