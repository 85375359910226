import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Styles from "./AccordionStyle.module.scss";
import { StyleLoanId } from "../InlineStyle";
import Tooltip from "../ToolTip";
import * as Validator from "../../../utils/Validator";
import { Strings } from "../../../utils/Strings";
import Config from "../../../utils/i18n";
import Moment from "moment";
import openAccord from "../../../assets/img/openBtn.svg";
import closeAccord from "../../../assets/img/closeBtnNew.svg";

Config(Strings);
const Accord = (props) => {
  const { t } = useTranslation();
  const [btn, setBtn] = useState(false);
  const responseArray = props.response;
  return (
    <>
      <Accordion className={Styles.accordion}>
        <Card className={Styles.accordion_card}>
          {responseArray &&
            responseArray.products &&
            responseArray.products.map((product, index) => (
              <div
                className="d-flex flex-column justify-content-center"
                style={btn ? StyleLoanId.isBtnTrue : StyleLoanId.isBtnFalse}
                key={index}
              >
                <div className={Styles.active_loan_id_heading}>
                  {!btn ? "Active Loan ID" : ""}
                </div>
                <div className={`text-left ${Styles.active_loan_id}`}>
                  {product.properties && product.properties.loan_id}
                </div>
              </div>
            ))}
          <Card.Header
            className={`d-flex flex-row-reverse justify-content-between ${Styles.card_header_bg}`}
          >
            <div className={Styles.button_center_styling}>
              <Accordion.Toggle
                as={Button}
                variant="outline-warning border-0"
                eventKey="0"
                onClick={() => setBtn(!btn)}
              >
                {btn ? (
                  <img
                    src={closeAccord}
                    alt="collapse"
                    style={{ width: 40, position: "relative", left: -2 }}
                  />
                ) : (
                  <img src={openAccord} alt="expand" />
                )}
              </Accordion.Toggle>
              <div className="text-center" style={StyleLoanId.detailsText}>
                {!btn ? "Details" : null}
              </div>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body
              style={{ paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }}
            >
              <div className="container">
                {responseArray &&
                  responseArray.products &&
                  responseArray.products.map((product) => (
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div
                            className={`col-4 p-0 ${Styles.loan_date_and_acc_no}`}
                          >
                            {t("Loandate")}
                          </div>
                          <div className="col-1 p-0">:</div>
                          <div
                            className={`col-6 p-0 text-left ${Styles.loan_date}`}
                          >
                            {Moment(
                              product.properties &&
                                product.properties.disbursal_date
                            ).format("D MMMM, YYYY")}
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={`col-4 p-0 ${Styles.loan_date_and_acc_no}`}
                          >
                            {t("BnakAcc")}
                          </div>
                          <div className="col-1 p-0">:</div>
                          <div
                            className={`col-6 p-0 text-left ${Styles.acc_no}`}
                          >
                            {Validator.mobileNumberRegex(
                              product.properties &&
                                product.properties.account_num
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {responseArray &&
                  responseArray.products &&
                  responseArray.products.map(
                    (product) =>
                      product.properties &&
                      product.properties.loan_details.map((details) => (
                        <>
                          <div className="row mt-1 mb-1">
                            <div
                              className="col-6 text-left"
                              style={{ fontWeight: "bold", color: "#000" }}
                            >
                              {details.title}
                            </div>
                            <div className="col-6 text-right">
                              {details.toolTipText ? (
                                <span className={Styles.accord_tooltip}>
                                  <Tooltip
                                    tipIcon={Styles.accord_tooltip_position}
                                    content={details.toolTipText}
                                    stage={5}
                                  />
                                </span>
                              ) : null}
                              <span
                                style={{
                                  color: details.color,
                                  fontWeight: "bold",
                                }}
                              >
                                {details.description}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className={`col-6 text-left ${Styles.subtitle_text}`}
                            >
                              <span>{details.subTitle}</span>
                            </div>
                          </div>
                        </>
                      ))
                  )}
              </div>
              {responseArray &&
                responseArray.products.map((product) => (
                  <div className={Styles.repayment_amount}>
                    <span className={Styles.repayment_amount_text}>
                      REPAYMENT AMOUNT
                    </span>
                    <span className={Styles.repayment_amount_value}>
                      {t("dollarSymbol")}
                      {Validator.DecimalConversion(
                        product.properties &&
                          product.properties.expected_total_amt,
                        1
                      )}
                    </span>
                  </div>
                ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default Accord;
