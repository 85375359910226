import {
  FETCH_LOAN_HISTORY_REQUEST,
  FETCH_LOAN_HISTORY_SUCCESS,
  FETCH_LOAN_HISTORY_FAIL,
  CLEAR_LOAN_HISTORY,
} from "../utils/Types";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LOAN_HISTORY_REQUEST:
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };

    case FETCH_LOAN_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case FETCH_LOAN_HISTORY_FAIL:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case CLEAR_LOAN_HISTORY:
      return {
        ...(state.basicInfoProfilePending = ""),
        ...(action.payload = ""),
      };

    default:
      return state;
  }
}
