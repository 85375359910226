

export const Styles = {
	dayContainer: {
		position: "relative",
	},
	day: {
		position: "absolute",
		right: 0,
		top: 20,
		left: -6,
	},
	daysText: {
		position: "relative",
		top: -22,
		right: 0,
		left: -3,
	},
	fortext: {
		color: "#1D2A78",
		fontSize: 16,
		fontWeight: "bold",
	},
	containerStyle:{
		marginTop:-30,
	}
};

export const ToolTipStyle = {
	background: "#EEBF31",
	text: "#231F20",
};
export const RepaySuccess ={
	backgroundColor:"#c3e3d1"
}
export const StyleLoanId = {
	isBtnTrue:{
		position:'absolute',
		paddingLeft:10,
		bottom:13
	},
	isBtnFalse:{
		position:'absolute',
		paddingLeft:10,
		paddingRight:10,
		width:'65%',
	},
	detailsText:{
		fontWeight:600,
		color:'#000'
	}
}