import React, { useEffect } from "react";
import FailIcon from "../../assets/img/Fail.svg";
import "./Fail.scss";

function Fail(props) {
  const { ChangeActiveComp } = props;

  const navigate = () => {
    ChangeActiveComp("activeLoan");
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("phonepeFinalSuccess");
      sessionStorage.removeItem("phonepeSuccess");
      sessionStorage.removeItem("phonepeFail");
    };
  }, []);

  return (
    <div className="loginMainDiv">
      <div className="loginSubDiv">
        <p className="welcomeTxt">Sorry!</p>
        <p className="nameText">
          {sessionStorage.getItem("panName") &&
            sessionStorage.getItem("panName")}
        </p>
        <img className="successImg" src={FailIcon} alt="Fail" />
        <p className="failText">Payment Failed</p>

        <button className="customBtn" onClick={navigate}>
          OK
        </button>
      </div>
    </div>
  );
}

export default Fail;
