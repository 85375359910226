import { combineReducers } from "redux";
import RepaymentOptions from "../reducers/RepaymentOptions";
import RepaymentInitiate from "../reducers/RepaymentInitiate";
import RepaymentHome from "../reducers/RepaymentHome";
import ProfileDetails from "../reducers/ProfileDetails";
import LoanHistory from "./Loanhistory";
import AccountDetailsData from "./AccountDetails";
import homePageApiProducts from "./homePageApiProducts";

export default combineReducers({
  RepaymentOptions,
  RepaymentInitiate,
  RepaymentHome,
  ProfileDetails,
  LoanHistory,
  AccountDetailsData,
  homePageApiProducts,
});
