import {
    REPAYMENT_INITIATE_START,
    REPAYMENT_INITIATE_SUCCESS,
    REPAYMENT_INITIATE_FAIL
} from "../utils/Types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case REPAYMENT_INITIATE_START:
            return {
                ...state,
                ...action.payload,
                isLoading: true,
            };

        case REPAYMENT_INITIATE_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false,
            };

        case REPAYMENT_INITIATE_FAIL:
            return {
                ...state,
                ...action.payload,
                isLoading: false,
            };

        default:
            return state;
    }
}
