import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./UserDetails.scss";
import { AccountDetailsData } from "../../redux/actions/AccountDetails";

const AccountDetails = (props) => {
  useEffect(() => {
    props.AccountDetailsData();
  }, []);

  useEffect(() => {
    if (props.apiState) {
      if (props.apiState.status === "UNAUTHORIZED") {
        props.ChangeActiveComp("login");
      }
    }
  }, [props.apiState]);

  return (
    <>
      <div className="continer-fluid text-left">
        <div className="row">
          <div className="col-md-10">
            {props.state &&
              props.state.map((el) => {
                return (
                  <div
                    className="row mt-3"
                    style={{
                      boxShadow: "0px 0px  10px #00000065",
                    }}
                  >
                    <div className="col-5">
                      <div className="profileDetails">
                        <ul className="leftUl">
                          <li>Bank Name </li>
                          <li>Account No</li>
                          <li>IFSC Code</li>
                          <li>Branch</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-7 text-left">
                      <div className="profileDetails">
                        <ul className="rightUl">
                          <li>{el.bank_name}</li>
                          <li>{el.acc_no.replace(/\w(?=\w{4})/g, "*")}</li>
                          <li>{el.ifsc_code}</li>
                          <li>{el.branch}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => {
  return {
    state:
      state.AccountDetailsData &&
      state.AccountDetailsData.data &&
      state.AccountDetailsData.data.results &&
      state.AccountDetailsData.data.results[0].properties &&
      state.AccountDetailsData.data.results[0].properties.userBanksList,
    apiState: state.AccountDetailsData && state.AccountDetailsData.data,
  };
};
const mapDispatch = {
  AccountDetailsData,
};

export default connect(mapState, mapDispatch)(AccountDetails);
