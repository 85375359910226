import React from "react";
import "./DeleteSuccess.scss";
import SuccessImg from "../../assets/img/success.svg";
function DeleteSuccess(props) {
  const { ChangeActiveComp } = props;
  const goToLogin = () => {
    ChangeActiveComp("userAuth", "");
  };
  return (
    <div
      className="deleteLoginMainDiv heightDiv mobileViewHeight"
      style={{ backgroundColor: "white", color: "white" }}
    >
      <div className="parentBlock">
        <div className="firstChildDiv">
          <p className="success__text">Account Deleted Successfully</p>
        </div>
        <div className="success__img">
          <img src={SuccessImg} alt="success" />
        </div>
        <div>
          <button className={"customBtnVvpl"} onClick={goToLogin}>
            CONTINUE
          </button>
          <p className="contact__text">
            In case you need furthur assistance, Please feel free to contact our
            customer service at{" "}
            <a
              href="mailto:https://www.gmail.com/support@loanfront.in"
              target="_blank"
            >
              {" "}
              support@loanfront.in
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default DeleteSuccess;
