import axios from "axios";
import { baseURL } from "../utils/ApiRequestPaths";
import { tokenConfig } from "../utils/Configuration";
import {
  REPAYMENT_HOME_START,
  REPAYMENT_HOME_SUCCESS,
  REPAYMENT_HOME_FAIL,
} from "../utils/Types";

export const repaymentHome = () => (dispatch, getState) => {
  dispatch({ type: REPAYMENT_HOME_START });
  axios
    .get(`${baseURL}/user/home?trg_ru=1&`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: REPAYMENT_HOME_SUCCESS,
        payload: res,
      });
      return;
    })
    .catch((err) => {
      dispatch({
        type: REPAYMENT_HOME_FAIL,
        payload: err.response,
      });
    });
};
