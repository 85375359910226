import axios from "axios";
import { baseURL, reactURL } from "../utils/ApiRequestPaths";
import { tokenConfig } from "../utils/Configuration";
import {
  REPAYMENT_INITIATE_START,
  REPAYMENT_INITIATE_SUCCESS,
  REPAYMENT_INITIATE_FAIL,
} from "../utils/Types";
import { PHONE_PE_CODE } from "../../utils/Constants";

export const repaymentInitiate =
  (loanId, pgCode, scheduleId, couponId) => (dispatch, getState) => {
    const body = {
      properties: {
        loan_id: loanId,
        pg_code: pgCode,
        schedule_id: scheduleId,
        coupon_application_id: couponId,
        paymentMode: pgCode===PHONE_PE_CODE ? "webpayment" : "",
        reactRedirectUrl: pgCode===PHONE_PE_CODE ? `${reactURL}/phonePe`: ""
      },
    };

    dispatch({ type: REPAYMENT_INITIATE_START });

    axios
      .post(`${baseURL}/repayments/initiate?`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: REPAYMENT_INITIATE_SUCCESS,
          payload: res,
        });
        return;
      })
      .catch((err) => {
        dispatch({
          type: REPAYMENT_INITIATE_FAIL,
          payload: err.response,
        });
      });
  };
