import axios from "axios";
import { baseURL } from "../utils/ApiRequestPaths";
// import { returnErrors } from "../utils/messages";
import {
  HOME_PAGE_API_PRODUCTS_LIST_START,
  HOME_PAGE_API_PRODUCTS_LIST_SUCCESS,
  HOME_PAGE_API_PRODUCTS_LIST_FAIL,
} from "../utils/Types";
// import { tokenConfig_homePage } from "../utils/Configuration";
import { tokenConfig } from "../utils/Configuration";

// import * as localStorageValues from "../../localStorageValues";

export const homePageApiProducts = () => (dispatch, getState) => {
  dispatch({ type: HOME_PAGE_API_PRODUCTS_LIST_START });

  axios
    .get(`${baseURL}/user/home?trg_ru=1&`, tokenConfig(getState))
    // .get(
    //   `${baseURL}/user/home?trg_ru=1&v=${localStorageValues.getBuildNumber()}&latitude=12.960801841691136&longitude=77.63618764467537`,
    //   tokenConfig(getState)
    // )

    .then((res) => {
      dispatch({
        type: HOME_PAGE_API_PRODUCTS_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: HOME_PAGE_API_PRODUCTS_LIST_FAIL,
      });
    });
};

export function home_api_products(getState) {
  var options = {
    headers: {
      "Content-Type": "application/json",
      appType: "in.loanfront.android",
      // _ta: localStorageValues.getSavedToken(),
    },
  };

  return (
    axios
      .get(`${baseURL}/user/home?trg_ru=1&`, tokenConfig(getState))
      // .get(
      //   `${baseURL}/user/home?trg_ru=1&v=${localStorageValues.getBuildNumber()}&latitude=12.960801841691136&longitude=77.63618764467537`,
      //   options
      // )

      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err && err.response && err.response.data;
      })
  );
}

export function sendLinkedInAutorizationCode(code, state) {
  var options = {
    headers: {
      "Content-Type": "application/json",
      appType: "in.loanfront.android",
    },
  };

  return axios
    .get(
      `${baseURL}/auth/social/linkedin/callback?state=${state}&code=${code}&origin=webview`,
      options
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
}

export function handlePerfiosSuccessApi() {
  var options = {
    headers: {
      "Content-Type": "application/json",
      appType: "in.loanfront.android",
      // _ta: localStorageValues.getSavedToken(),
    },
  };

  return axios
    .get(`${baseURL}/perfios/update_status`, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err && err.response && err.response.data;
    });
}

export function handleDigitapSuccessApi() {
  var options = {
    headers: {
      "Content-Type": "application/json",
      appType: "in.loanfront.android",
      // _ta: localStorageValues.getSavedToken(),
    },
  }; 

  return axios
    .get(`${baseURL}/digitap/incomevalidation/update_status`, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err && err.response && err.response.data;
    });
}
