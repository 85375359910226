import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Loanhistory } from "../../redux/actions/LoanHistory";

const LoanHistory = (props) => {
  const [loanData, setLoanData] = useState([]);
  const [showLoanDetails, setShowLoanDetails] = useState(false);
  const [loanBorder, setLoanBorder] = useState(false);

  const getLoanHistory = (loanId, indx) => {
    setLoanBorder(indx);
    setShowLoanDetails(true);
    const LoanDetailsData =
      props.loanDetails &&
      props.loanDetails.filter((el) => {
        return el.properties.loan_id === loanId;
      });
    setLoanData(LoanDetailsData);
  };

  useEffect(() => {
    props.Loanhistory();
  }, []);

  useEffect(() => {
    if (props.apiData) {
      if (props.apiData.status === "UNAUTHORIZED") {
        props.ChangeActiveComp("login");
      }
    }
  }, [props.apiData]);

  return (
    <>
      <div
        className="continer-fluid text-left"
        style={{ marginBottom: "64px" }}
      >
        <div className="row">
          <div className="col-md-5">
            <div className="LoanHistory">
              {props.loanDetails &&
                props.loanDetails.map((el, indx) => {
                  return (
                    <div
                      className={
                        loanBorder == indx ? "loanBorder card" : "card"
                      }
                      onClick={() =>
                        getLoanHistory(el.properties.loan_id, indx)
                      }
                    >
                      <div className="card-title m-0">
                        <span>Loan Id: </span>
                        <span>{el.properties.loan_id}</span>
                      </div>

                      <div className="card-body">
                        <span>Loan Amount: </span>
                        <span>{el.properties.principal_amt}</span>
                      </div>
                    </div>
                  );
                })}
              {props.loanDetails &&
                props.loanDetails.map((el, indx) => {
                  return (
                    <div
                      className={
                        loanBorder == indx ? "loanBorder card" : "card"
                      }
                      onClick={() =>
                        getLoanHistory(el.properties.loan_id, indx)
                      }
                    >
                      <div className="card-title m-0">
                        <span>Loan Id: </span>
                        <span>{el.properties.loan_id}</span>
                      </div>

                      <div className="card-body">
                        <span>Loan Amount: </span>
                        <span>{el.properties.principal_amt}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="col-md-7">
            <div className="LoanHistory">
              {showLoanDetails ? (
                loanData.map((el) => {
                  return (
                    <div
                      className="card"
                      style={{ border: "2px solid #1d2a78" }}
                    >
                      <div className="card-title m-0">
                        <span>Loan Amount: </span>
                        <span>{el.properties.principal_amt}</span>
                      </div>
                      <div className="card-body">
                        <ul>
                          <li>
                            <span>Tenure Days : </span>
                            <span>{el.properties.tenure_days}</span>
                          </li>
                          <li>
                            <span>Interset Amount : </span>
                            <span>{el.properties.interest_amount}</span>
                          </li>
                          <li>
                            <span>Emi Amount : </span>
                            <span>{el.properties.emi_amount}</span>
                          </li>
                          <li>
                            <span>Status: </span>
                            <span>
                              {el.properties.status_code.replaceAll("_", " ")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="card" style={{ border: "2px solid #1d2a78" }}>
                  <div className="card-title m-0">
                    <span>Loan Amount: </span>
                    <span>
                      {props.loanDetails &&
                        props.loanDetails[0].properties.principal_amt}
                    </span>
                  </div>
                  <div className="card-body">
                    <ul>
                      <li>
                        <span>Tenure Days : </span>
                        <span>
                          {props.loanDetails &&
                            props.loanDetails[0].properties.tenure_days}
                        </span>
                      </li>
                      <li>
                        <span>Interset Amount : </span>
                        <span>
                          {props.loanDetails &&
                            props.loanDetails[0].properties.interest_amount}
                        </span>
                      </li>
                      <li>
                        <span>Emi Amount : </span>
                        <span>
                          {props.loanDetails &&
                            props.loanDetails[0].properties.emi_amount}
                        </span>
                      </li>
                      <li>
                        <span>Status: </span>
                        <span>
                          {props.loanDetails &&
                            props.loanDetails[0].properties.status_code.replaceAll(
                              "_",
                              " "
                            )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapstate = (state) => {
  return {
    loanDetails: state.LoanHistory.data && state.LoanHistory.data.results,
    apiData: state.LoanHistory && state.LoanHistory.data,
  };
};
const mapDispatch = {
  Loanhistory,
};

export default connect(mapstate, mapDispatch)(LoanHistory);
