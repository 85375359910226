import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { repaymentHome } from "../../redux/actions/RepaymentHome";
import { connect } from "react-redux";
import { Strings } from "../../utils/Strings";
import { useTranslation, Trans } from "react-i18next";
import Config from "../../utils/i18n";
import Styles from "./RepaymentHome.module.scss";
import LfiStyle from "./lfi.module.scss";
import { RepaySuccess } from "./InlineStyle";
import Repaysuccess from "../../assets/img/Repaysuccess.svg";
import Accord from "./Accord/Accord";
import Moment from "moment";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import { ToastMessageHandler, CommaSeperator } from "../../utils/Validator";
// import { DefaultLoading } from "@capfront-technologies/loanfront-ui-components";
import { DefaultLoading } from "../Container/Loader/LoaderScreen";
import { repaymentInitiate } from "../../redux/actions/RepaymentInitiate";
import MainHeader from "../../reusableComponents/MainHeader";
Config(Strings);

const ActiveHomeLoan = (props) => {
  const { ChangeActiveComp } = props;
  const { t } = useTranslation();
  const [OverDueValue, SetOverDueValue] = useState(0);
  const [LfiNum, setLfiNum] = useState("");
  const [TotalPayableAmount, SetTotalPayableAmount] = useState("");
  const [homeApiCalled, SetHomeApiCalled] = useState(false);
  const [verify_repayment_page, setverify_repayment_page] =
    React.useState(true);
  const PayableAmount = t("payNowString").formatUnicorn(
    props.repaymentHomeResult &&
      props.repaymentHomeResult.scheduleList &&
      props.repaymentHomeResult.scheduleList[0].properties.due_amount
  );

  var sub_array = [];

  useEffect(() => {
    if (sessionStorage.getItem("_ta")) {
      performApiCall();
    }
    localStorage.removeItem("permissionError");
    sessionStorage.removeItem("adjustAmount");
    sessionStorage.removeItem("penalityAmount");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performApiCall = () => {
    SetHomeApiCalled(true);
    props.repaymentHome();
  };

  useEffect(() => {
    if (
      homeApiCalled &&
      props.repaymentHomeResult &&
      props.repaymentHomeResult
    ) {
      setverify_repayment_page(false);
      if (props.repaymentHomeResult.context === "activeloan") {
        if (props.repaymentHomeResult.products) {
          if (props.repaymentHomeResult.products.length > 0) {
            if (props.repaymentHomeResult.products[0].properties) {
              sessionStorage.setItem(
                "lender",
                props.repaymentHomeResult.products[0].properties
                  .disbursal_type_code
              );
              if (
                props.repaymentHomeResult.products[0].properties.status_code
              ) {
                if (
                  props.repaymentHomeResult.products[0].properties
                    .status_code === "disbursal_completed" ||
                  props.repaymentHomeResult.products[0].properties
                    .status_code === "disbursal_inprogress"
                ) {
                  SetTotalPayableAmount(
                    props.repaymentHomeResult &&
                      props.repaymentHomeResult.scheduleList &&
                      props.repaymentHomeResult.scheduleList[0].properties
                        .expected_total_amt
                  );
                  let ScheduleVal =
                    props.repaymentHomeResult &&
                    props.repaymentHomeResult.scheduleList &&
                    props.repaymentHomeResult.scheduleList;
                  if (ScheduleVal) {
                    for (let i = 0; i < ScheduleVal.length; i++) {
                      if (
                        ScheduleVal[i].properties.status_code ==
                        "repayment_pending"
                      ) {
                        sub_array.push(ScheduleVal[i]);
                        if (sub_array[0].properties.isOverDue == 1) {
                          setLfiNum(sub_array[0].properties.schedule_num);
                          SetOverDueValue(sub_array[0].properties.isOverDue);
                          SetTotalPayableAmount(
                            sub_array[0].properties.expected_total_amt
                          );
                        } else {
                          setLfiNum(sub_array[0].properties.schedule_num);
                          SetOverDueValue(sub_array[0].properties.isOverDue);
                          SetTotalPayableAmount(
                            sub_array[0].properties.expected_total_amt
                          );
                        }
                      }
                    }
                  }
                } else {
                  ChangeActiveComp("download", "");
                }
              } else {
                ChangeActiveComp("download", "");
              }
            } else {
              ChangeActiveComp("download", "");
            }
          } else {
            ChangeActiveComp("download", "");
          }
        } else {
          ChangeActiveComp("download", "");
        }
      } else {
        ChangeActiveComp("download", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.repaymentHomeResult]);

  const Paynow = () => {
    if (props.repaymentHomeResult && props.repaymentHomeResult.scheduleList) {
      for (let i = 0; i < props.repaymentHomeResult.scheduleList.length; i++) {
        if (
          props.repaymentHomeResult.scheduleList[i].properties.status_code !==
          "repayment_transaction_success"
        ) {
          props.repaymentHomeResult.scheduleList[
            i
          ].properties.schedule_details.map((el) => {
            el.fieldName === "adjust_amt" &&
              sessionStorage.setItem("adjustAmount", el.description);
          });
          sessionStorage.setItem("repaymentAmount", TotalPayableAmount);
          sessionStorage.setItem(
            "loanId",
            props.repaymentHomeResult.products[0].properties.loan_id
          );
          sessionStorage.setItem(
            "loanAmount",
            props.repaymentHomeResult.scheduleList[i].properties
              .expected_total_amt
          );
          sessionStorage.setItem(
            "LFI",
            props.repaymentHomeResult.scheduleList[i].properties.schedule_num
          );
          sessionStorage.setItem(
            "LFI_Amount",
            props.repaymentHomeResult.scheduleList[i].properties.spa
          );
          sessionStorage.setItem(
            "interest_Amount",
            props.repaymentHomeResult.scheduleList[i].properties.interest_amount
          );
          sessionStorage.setItem(
            "loanScheduleId",
            props.repaymentHomeResult.scheduleList[i].properties
              .loan_schedule_id
          );
          if (
            props.repaymentHomeResult.scheduleList[i].properties.penalty_amt > 0
          ) {
            sessionStorage.setItem(
              "penalityAmount",
              props.repaymentHomeResult.scheduleList[i].properties.penalty_amt
            );
          }
          sessionStorage.setItem("isCouponApplied", false);
          sessionStorage.setItem("couponId", 0);
          checkSchedules(props.repaymentHomeResult.scheduleList);
          ChangeActiveComp("paymentScreen");
          return;
        }
      }
    }
  };

  const checkSchedules = (schedules) => {
    let val = 0;
    if (schedules) {
      schedules.map((el) => {
        if (el.properties.status_code !== "repayment_transaction_success") {
          val += 1;
        }
      });
    }
    sessionStorage.setItem("schedulesPending", val);
  };

  useEffect(() => {
    if (
      homeApiCalled &&
      props.repaymentHomeResultError &&
      props.repaymentHomeResultError.message
    ) {
      if (props.repaymentHomeResultError.status === "UNAUTHORIZED") {
        props.ChangeActiveComp("login");
      }
      setverify_repayment_page(false);
      ToastMessageHandler(props.repaymentHomeResultError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.repaymentHomeResultError]);

  return (
    <>
      {verify_repayment_page || props.isrepaymentHomeLoading ? (
        <DefaultLoading />
      ) : (
        <div className={Styles.repayment_super_outer_container}>
          <div className={Styles.repayment_outer_wrapper_container}>
            <MainHeader onclick={ChangeActiveComp} />
            <ToastsContainer
              position={ToastsContainerPosition.BOTTOM_CENTER}
              store={ToastsStore}
            />
            <div
              className="container mt-0"
              style={{ padding: "28px", width: "auto", maxWidth: "420px" }}
            >
              <div>
                <Accord
                  response={
                    props.repaymentHomeResult && props.repaymentHomeResult
                  }
                />
                <div className={Styles.pillar_without_expand}>
                  {props.repaymentHomeResult &&
                    props.repaymentHomeResult.scheduleList &&
                    props.repaymentHomeResult.scheduleList.map(
                      (schedule, index) =>
                        schedule.properties.status_code ===
                        "repayment_pending" ? (
                          <Accordion
                            key={schedule.properties.schedule_num}
                            allowZeroExpanded
                            className={Styles.accordion}
                          >
                            <AccordionItem>
                              <AccordionItemButton
                                className={Styles.accordion_button}
                              >
                                <>
                                  <div
                                    className={
                                      schedule.properties.isOverDue === 1
                                        ? LfiStyle.lfi_pillar_overdue
                                        : LfiStyle.lfi_pillar
                                    }
                                  ></div>
                                  <div
                                    className={
                                      schedule.properties.schedule_num == LfiNum
                                        ? `${
                                            schedule.properties.isOverDue === 1
                                              ? LfiStyle.wrapper_lfi_overdue
                                              : LfiStyle.wrapper_lfi
                                          } mt-2`
                                        : `${LfiStyle.wrapper_lfi} mb-3 mt-2 ${Styles.wrapperLfi_without_border}`
                                    }
                                    /*eslint eqeqeq: "off"*/
                                    style={{ marginBottom: 5 }}
                                  >
                                    <div
                                      className={
                                        schedule.properties.isOverDue === 1
                                          ? LfiStyle.sub_one_overdue
                                          : LfiStyle.sub_one
                                      }
                                    >
                                      <div
                                        className={
                                          schedule.properties.isOverDue === 1
                                            ? LfiStyle.lfi_text_overdue
                                            : LfiStyle.lfi_text
                                        }
                                      >
                                        {t("lfi")}
                                        {schedule.properties.schedule_num}
                                      </div>
                                    </div>
                                    <div className={LfiStyle.sub_two}>
                                      <div className={LfiStyle.amount}>
                                        <span
                                          className={
                                            schedule.properties.isOverDue === 1
                                              ? LfiStyle.span_on_overdue
                                              : null
                                          }
                                        >
                                          {t("dollarSymbol")}
                                        </span>
                                        <span
                                          className={
                                            schedule.properties.isOverDue === 1
                                              ? LfiStyle.span_on_overdue
                                              : null
                                          }
                                        >
                                          {CommaSeperator(
                                            schedule.properties.due_amount
                                          )}
                                        </span>
                                        <span
                                          className={
                                            schedule.properties.isOverDue === 1
                                              ? LfiStyle.penalty_amt_value
                                              : null
                                          }
                                        >
                                          {schedule.properties.isOverDue ===
                                            1 &&
                                          schedule.properties.penalty_amt &&
                                          schedule.properties.penalty_amt
                                            ? " + " +
                                              t("dollarSymbol") +
                                              schedule.properties.penalty_amt
                                            : ""}
                                        </span>
                                      </div>

                                      <div className={LfiStyle.date}>
                                        {schedule.properties.isOverDue === 1 ? (
                                          <span
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {t("dueOnSince")}
                                          </span>
                                        ) : (
                                          <Trans
                                            components={{
                                              uppercase: (
                                                <span className="text-uppercase" />
                                              ),
                                            }}
                                          >
                                            {t("dueOn")}
                                          </Trans>
                                        )}{" "}
                                        {Moment(
                                          schedule.properties.due_date
                                        ).format("DD/MM/YYYY")}
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        schedule.properties.isOverDue === 1
                                          ? LfiStyle.sub_three_overdue
                                          : LfiStyle.sub_three
                                      }
                                    >
                                      <div
                                        className={
                                          LfiStyle.schedule_lfi_details
                                        }
                                      >
                                        <div className={LfiStyle.due}>
                                          {schedule.properties.isOverDue === 1
                                            ? t("overDue")
                                            : t("dueIn")}
                                        </div>
                                        <div className={LfiStyle.day}>
                                          {schedule.properties.remaining_days}
                                        </div>
                                        <div className={LfiStyle.days}>
                                          {t("days")}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </AccordionItemButton>
                              <AccordionItemPanel style={{ padding: 0 }}>
                                <>
                                  <div className="container p-0">
                                    <div
                                      className={
                                        schedule.properties.schedule_num ==
                                          LfiNum &&
                                        schedule.properties.isOverDue === 1
                                          ? Styles.lfi_body_container_overdue
                                          : schedule.properties.schedule_num ==
                                              LfiNum &&
                                            schedule.properties.isOverDue === 0
                                          ? Styles.lfi_body_container
                                          : Styles.lfi_body_without_border
                                      }
                                    >
                                      <div className="card border-0 rounded-0">
                                        <div className="card-body">
                                          {schedule.properties.schedule_details.map(
                                            (detail, index) => (
                                              <div
                                                className="row"
                                                key={index}
                                                style={{ color: detail.color }}
                                              >
                                                <div
                                                  className="col-6 text-left"
                                                  style={{
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {detail.title ===
                                                  "Late Payment Fee" ? (
                                                    <div>
                                                      {detail.title}
                                                      <span
                                                        style={{ fontSize: 12 }}
                                                        className="d-block"
                                                      >
                                                        {t(
                                                          "inclusiveLatePaymentFee"
                                                        )}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    detail.title
                                                  )}
                                                </div>
                                                <div
                                                  className={`col-6 text-right ${LfiStyle.accordion_panel_values}`}
                                                >
                                                  {detail.description}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </AccordionItemPanel>
                            </AccordionItem>
                          </Accordion>
                        ) : (
                          ""
                        )
                    )}
                </div>
                <div className={Styles.pillar_without_expand}>
                  {props.repaymentHomeResult &&
                    props.repaymentHomeResult.scheduleList &&
                    props.repaymentHomeResult.scheduleList.map((schedule) =>
                      schedule.properties.status_code ===
                      "repayment_transaction_success" ? (
                        <Accordion
                          key={schedule.properties.schedule_num}
                          allowZeroExpanded
                          className={Styles.accordion}
                        >
                          <AccordionItem>
                            <AccordionItemButton
                              className={Styles.accordion_button}
                            >
                              <>
                                <div
                                  className={LfiStyle.lfi_pillar_success}
                                ></div>
                                <div
                                  className={`${LfiStyle.wrapper_lfi_on_success} mb-3 mt-2 ${Styles.wrapperLfi_without_border}`}
                                  style={{
                                    backgroundColor:
                                      RepaySuccess.RepaySuccessStyle,
                                  }}
                                >
                                  <div className={LfiStyle.sub_one_success}>
                                    <div className={LfiStyle.lfi_text_overdue}>
                                      {t("lfi")}
                                      {schedule.properties.schedule_num}
                                    </div>
                                  </div>
                                  <div className={LfiStyle.sub_two_success}>
                                    <div className={LfiStyle.amount}>
                                      {t("dollarSymbol")}
                                      {schedule.properties.repaid_amt}
                                    </div>

                                    <div className={LfiStyle.date}>
                                      <Trans
                                        components={{
                                          uppercase: (
                                            <span className="text-uppercase" />
                                          ),
                                        }}
                                      >
                                        {t("paidOn")}
                                      </Trans>{" "}
                                      {Moment(
                                        schedule.properties.repaid_date
                                      ).format("DD/MM/YYYY")}
                                    </div>
                                  </div>
                                  <div className={LfiStyle.sub_four}>
                                    <div
                                      className={LfiStyle.schedule_lfi_details}
                                    >
                                      <img
                                        src={Repaysuccess}
                                        style={{ height: 30 }}
                                        alt="success"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            </AccordionItemButton>
                            <AccordionItemPanel style={{ padding: 0 }}>
                              <>
                                <div className="container p-0">
                                  <div
                                    className={Styles.lfi_body_without_border}
                                  >
                                    <div className="card border-0">
                                      <div className="card-body">
                                        {schedule.properties.schedule_details.map(
                                          (detail, index) => (
                                            <div
                                              className="row"
                                              key={index}
                                              style={{ color: detail.color }}
                                            >
                                              <div
                                                className="col-6 text-left"
                                                style={{
                                                  color: "#000000",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {detail.title ===
                                                "Late Payment Fee" ? (
                                                  <div>
                                                    {detail.title}
                                                    <span
                                                      style={{ fontSize: 12 }}
                                                      className="d-block"
                                                    >
                                                      {t(
                                                        "inclusiveLatePaymentFee"
                                                      )}
                                                    </span>
                                                  </div>
                                                ) : (
                                                  detail.title
                                                )}
                                              </div>
                                              <div
                                                className="col-6 text-right"
                                                style={{ fontWeight: 600 }}
                                              >
                                                {detail.description}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      ) : (
                        ""
                      )
                    )}
                </div>
                <div className={Styles.paynow_Button}>
                  <button
                    onClick={Paynow}
                    style={{
                      backgroundColor:
                        OverDueValue && OverDueValue === 1
                          ? "#c92526"
                          : "#1d2a78",
                    }}
                  >
                    {t("payNowString").formatUnicorn(TotalPayableAmount)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    isrepaymentHomeLoading: state.RepaymentHome.isLoading,
    repaymentHomeResult:
      state.RepaymentHome &&
      state.RepaymentHome.data &&
      state.RepaymentHome.data.results,
    isRepaymentIntiateLoading: state.RepaymentInitiate.isLoading,
    repaymentHomeResultError: state.RepaymentHome && state.RepaymentHome.data,
    repaymentInitiateResult:
      state.RepaymentInitiate && state.RepaymentInitiate.data,
    repaymentInitiateResultError:
      state.RepaymentInitiate && state.RepaymentInitiate.data,
  };
};
const mapDispatch = {
  repaymentHome,
  repaymentInitiate,
};

export default connect(mapState, mapDispatch)(ActiveHomeLoan);
