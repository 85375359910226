import axios from "axios";
import { baseURL } from "../utils/ApiRequestPaths";
import { tokenConfig } from "../utils/Configuration";
import {
  FETCH_LOAN_HISTORY_REQUEST,
  FETCH_LOAN_HISTORY_SUCCESS,
  FETCH_LOAN_HISTORY_FAIL,
  CLEAR_LOAN_HISTORY,
} from "../utils/Types";

export const Loanhistory = () => (dispatch, getState) => {
  dispatch({ type: FETCH_LOAN_HISTORY_REQUEST });
  axios
    .get(`${baseURL}/user/loans?loan_status=history`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FETCH_LOAN_HISTORY_SUCCESS,
        payload: res,
      });
      return;
    })
    .catch((err) => {
      dispatch({
        type: FETCH_LOAN_HISTORY_FAIL,
        payload: err.response,
      });
    });
};

export const clearLoanhistory = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_LOAN_HISTORY });
};
