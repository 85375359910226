import React from "react";
import "./LoaderScreen.scss";

export function DefaultLoading(props) {
  return (
    <div className="loaderOuter">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="innerSolidCircle"></div>
      <p>{props.title ? props.title : "Fetching Details"}</p>
    </div>
  );
}
