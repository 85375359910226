import * as localStorageValues from "../../localstorage/LocalStorageValues";

export const tokenConfig = (getState) => {
  const token = sessionStorage.getItem("_ta") && sessionStorage.getItem("_ta");
  const config = {
    headers: {
      "Content-Type": "application/json",
      appType: "in.loanfront.android",
    },
  };
  if (token) {
    config.headers["_ta"] = token;
  }

  const params = new URLSearchParams();
  params.append("v", 3021);
  params.append("latitude", localStorageValues.getSavedLatitude());
  params.append("longitude", localStorageValues.getSavedLongitude());

  config.params = params;

  return config;
};
