import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import "./upiPay.scss";
import MainHeader from "../../reusableComponents/MainHeader";
import phonepe from "../../assets/img/phonepe.svg";
import googlepay from "../../assets/img/googlepay.svg";
import paytm from "../../assets/img/paytm.svg";
import { UPI_CODE } from "../../utils/Constants";
import { repaymentInitiate } from "../../redux/actions/RepaymentInitiate";
// import { DefaultLoading } from "@capfront-technologies/loanfront-ui-components";
import { DefaultLoading } from "../Container/Loader/LoaderScreen";
import { connect } from "react-redux";
// import { onMessageListener } from "../../Firebase/Firebase";
// import { getToken } from "../../Firebase/Firebase";
import { baseURL } from "../../redux/utils/ApiRequestPaths";
import { tokenConfig } from "../../redux/utils/Configuration";
import axios from "axios";
import backArrow from "../../assets/img/baseline_arrow.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpiPay(props) {
  const [api_enable, setapi_enabled] = useState(false);
  const [loadingQr, setLoadingQr] = useState(false);
  const [upiUrl, setUpiUrl] = useState("");
  const [isTokenFound, setTokenFound] = useState(false);
  const [callbackCalled, setCallbackCalled] = useState(false);

  useEffect(() => {
    callPaymentInitiate(UPI_CODE);
  }, []);

  // useEffect(() => {
  //   requestPermissionn();
  // }, [setTokenFound]);

  const goBack = () => {
    props.ChangeActiveComp("paymentScreen");
  };

  // async function tokenFunc() {
  //   let data;
  //   data = await getToken(setTokenFound);
  //   if (data) {
  //   }
  //   return data;
  // }

  // function requestPermissionn() {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === "granted") {
  //       localStorage.setItem("permissionError", "false");
  //       tokenFunc();
  //     } else {
  //       props.ChangeActiveComp("paymentScreen", "");
  //       localStorage.setItem("permissionError", "true");
  //     }
  //   });
  // }

  // onMessageListener()
  //   .then((payload) => {})
  //   .catch((err) => {});

  const callPaymentInitiate = (target) => {
    setapi_enabled(true);
    props.repaymentInitiate(
      sessionStorage.getItem("loanId"),
      target,
      sessionStorage.getItem("loanScheduleId"),
      sessionStorage.getItem("couponId")
    );
  };

  const makeUpiUrl = (data) => {
    if (data) {
      let PA = data.pay ? data.pay : null;
      let PN = data.pn ? data.pn : null;
      let TR = data.tr ? data.tr : null;
      let AM = data.transaction_amt ? data.transaction_amt : null;
      let CU = data.cu ? data.cu : null;
      let MC = data.mc ? data.mc : null;
      let TN = data.tn ? data.tn : null;
      let uri = `upi://pay?pa=${PA}&pn=${PN}&tr=${TR}&am=${AM}&cu=${CU}&mc=${MC}&tn=${TN}`;
      setUpiUrl(uri);
      setLoadingQr(false);
    }
  };

  const checkStatus = (refid) => {
    const data = {
      properties: {
        loan_schedule_id: sessionStorage.getItem("loanScheduleId"),
        loan_id: sessionStorage.getItem("loanId"),
        repayment_transaction_id: sessionStorage.getItem("repayTransId"),
        merchantTranId: sessionStorage.getItem("merchantTransId"),
        txnId: null,
        responseCode: null,
        approvalRefNo: null,
        txnRef: null,
        status: null,
      },
    };
    setCallbackCalled(true);
    axios
      .post(`${baseURL}/repayments/iciciupi/checkstatus?`, data, tokenConfig())
      .then((res) => {
        setCallbackCalled(false);
        if (res.status === 200) {
          if (res.data.status === "OK") {
            if (res.data.message === "repayment_transaction_pending") {
              toast.warn("Payment is pending", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (res.data.message === "repayment_transaction_success") {
              if (sessionStorage.getItem("schedulesPending") === "1") {
                props.ChangeActiveComp("finalSuccess", "");
              } else {
                props.ChangeActiveComp("success", "");
              }
            }
            if (res.data.message === "repayment_transaction_fail") {
              props.ChangeActiveComp("fail", "");
            }
          }
          if (res.data.status === "UNAUTHORIZED") {
            props.ChangeActiveComp("login", "");
          }
        }
      })
      .catch((err) => {
        setCallbackCalled(false);
        props.ChangeActiveComp("fail", "");
      });
  };

  useEffect(() => {
    if (
      api_enable &&
      props.repaymentInitiateResult &&
      props.repaymentInitiateResult
    ) {
      if (props.repaymentInitiateResult.status === "OK") {
        if (props.repaymentInitiateResult.results) {
          if (props.repaymentInitiateResult.results[0]) {
            if (props.repaymentInitiateResult.results[0].properties) {
              sessionStorage.setItem(
                "order_amount",
                props.repaymentInitiateResult.results[0].properties
                  .transaction_amt
              );
              sessionStorage.setItem(
                "repayTransId",
                props.repaymentInitiateResult.results[0].properties
                  .repayment_transaction_id
              );
              sessionStorage.setItem(
                "merchantTransId",
                props.repaymentInitiateResult.results[0].properties
                  .merchantTranId
              );
              makeUpiUrl(props.repaymentInitiateResult.results[0].properties);
            }
          }
        }
      }
      if (props.repaymentInitiateResult.status === "UNAUTHORIZED") {
        props.ChangeActiveComp("login", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.repaymentInitiateResult]);

  useEffect(() => {
    if (
      api_enable &&
      props.repaymentInitiateResultError &&
      props.repaymentInitiateResultError.message
    ) {
      if (props.repaymentInitiateResultError.status === "UNAUTHORIZED") {
        props.ChangeActiveComp("login", "");
      } else {
        setapi_enabled(false);
        props.ChangeActiveComp("paymentScreen", "");
        toast.error(props.repaymentInitiateResultError.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.repaymentInitiateResultError]);

  return (
    <>
      {props.isRepaymentIntiateLoading || loadingQr || callbackCalled ? (
        <DefaultLoading />
      ) : (
        <div className="loginMainDiv">
          <div className="paymentSubDiv">
            <MainHeader target="payment" />
            <ToastContainer
              style={{
                width: "300px",
                height: "80px",
              }}
            />
            <div className="nestedDiv">
              <div className="gobackDiv" onClick={goBack}>
                <img src={backArrow} /> Go Back
              </div>
              <div className="qrSubDiv">
                <p className="scanText">
                  Scan the QR using any UPI app on your phone like Phone Pe,
                  Google Pay, Paytm etc.
                </p>
                <div>
                  <img className="phonepeLogo" src={phonepe} />
                  <img className="gpayLogo" src={googlepay} />
                  <img className="paytmLogo" src={paytm} />
                </div>
                <div className="qrDiv">
                  <QRCode value={upiUrl} size={150} />
                </div>
                <button
                  style={{
                    marginTop: "18px",
                    padding: "8px",
                    background: "#1d2a78",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  onClick={checkStatus}
                >
                  Click to check payment status
                </button>
                <p className="mt-4 mb-0">
                  Don't click on back button or close the browser once
                  transaction is started
                </p>
                <button
                  onClick={() => props.ChangeActiveComp("paymentScreen")}
                  className="goBack mb-3"
                >
                  Change Payment Method ?
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapState = (state) => {
  return {
    isRepaymentIntiateLoading: state.RepaymentInitiate.isLoading,
    repaymentInitiateResult:
      state.RepaymentInitiate && state.RepaymentInitiate.data,
    repaymentInitiateResultError:
      state.RepaymentInitiate && state.RepaymentInitiate.data,
  };
};
const mapDispatch = {
  repaymentInitiate,
};

export default connect(mapState, mapDispatch)(UpiPay);
