import React, { useEffect, useState, useRef } from "react";
import Logo from "../../assets/img/LoanfrontColLogo.svg";
import "./OtpConfirm.scss";
import { baseURL } from "../../redux/utils/ApiRequestPaths";
import axios from "axios";
import CustomLoader from "../../utils/customLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import vaibhavLogo from "../../assets/img/vaibhavLogo.svg";

const OtpConfirm = (props) => {
  const { ChangeActiveComp, callResendOTPForDelete } = props;
  const [seconds, setSeconds] = useState(30);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [host, setHost] = useState("");

  const notify = (value) => toast.dark(value);

  const numRef = useRef();

  const handleKeyPress = (event) => {
    if (event.which === 13) {
      validateOtp();
    }
  };

  const onChangeHandler = (event) => {
    let { value } = event.target;
    if (value !== "") {
      apiMessage && setApiMessage("");
      let regex = /^[0-9]*$/;
      if (regex.test(value)) {
        setOtp(value);
        otpError && setOtpError(false);
      } else {
        setOtpError(true);
      }
    } else {
      setOtp(value);
      otpError && setOtpError(false);
    }
  };

  const resend = async () => {
    setLoading(true);
    setApiMessage("");
    const val = await callResendOTPForDelete();
    if (val) {
      notify("OTP sent successfully!");
      setSeconds(30);
      setOtp("");
      setOtpError(false);
      setLoading(false);
      setApiMessage(val);
    } else {
      setLoading(false);
      setSeconds(30);
      setOtp("");
      setOtpError(false);
      notify("OTP sent successfully!");
    }
  };

  const validateOtp = () => {
    if (otp) {
      const data = {
        properties: {
          user_id: sessionStorage.getItem("userId"),
          otp: otp,
          accountType: "delete_account",
        },
      };
      setLoading(true);
      axios
        .post(`${baseURL}/lf/delete_account/delete`, data)
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            if (res.data) {
              if (res.data.status === "OK") {
                if (res.data.results) {
                  if (res.data.results.status === "success") {
                    ChangeActiveComp("successDelete", "");
                  }
                } else {
                  // handle the error
                  setLoading(false);
                }
              }
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            if (err.response.data) {
              if (err.response.data.message) {
                setApiMessage(err.response.data.message);
              } else {
                setApiMessage("Please enter a valid OTP!");
              }
            }
          }
        });
    } else {
      setOtpError(true);
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
        setSeconds(0);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  useEffect(() => {
    numRef.current.focus();
    setHost(sessionStorage.getItem("host"));
  }, []);

  return (
    <div className="deleteLoginMainDiv">
      <div className="deleteLoginSubDiv">
        <div className="logoDiv">
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            newestOnTop={true}
            toastClassName="minimize_height"
          />
          <img
            src={host === "vaibhav-vyapaar" ? vaibhavLogo : Logo}
            alt="loanfront_logo"
            className={host === "vaibhav-vyapaar" ? "vaibhavLogo" : ""}
          />
          <p className="otpText">
            Confirm <b>OTP!</b>
          </p>
          <p className="otpTextPara">
            Please enter the OTP received on the registered mobile number to
            confirm the account deletion request
          </p>
          <p className="mobileNumber">
            {sessionStorage.getItem("mobile_number") &&
              `******${sessionStorage.getItem("mobile_number").slice(6, 10)}`}
          </p>
        </div>
        <div className="contentDiv mt-2">
          <input
            className="customInput"
            type="text"
            placeholder="Enter OTP"
            name="otp"
            onChange={onChangeHandler}
            value={otp}
            maxLength={6}
            ref={numRef}
            onKeyPress={handleKeyPress}
          />
          {otpError && (
            <span className="errorMessage">Please enter a valid OTP!</span>
          )}
          {apiMessage && <span className="errorMessage">{apiMessage}</span>}
          {seconds === 0 ? (
            <div className="d-flex flex-column">
              <div className="otp_recived">
                Haven’t got OTP yet? <span onClick={resend}>RESEND OTP</span>
              </div>
            </div>
          ) : (
            <div className="timer">
              {"("}
              {`00`} : {seconds < 10 ? `0${seconds}` : seconds}
              {")"}
            </div>
          )}
          {loading ? (
            <CustomLoader
              className={
                host === "vaibhav-vyapaar" ? "customBtnVvpl" : "customBtn"
              }
            />
          ) : (
            <button
              className={
                host === "vaibhav-vyapaar" ? "customBtnVvpl" : "customBtn"
              }
              onClick={validateOtp}
            >
              Verify OTP
            </button>
          )}
          <a
            onClick={() => ChangeActiveComp("userAuth", "OtpConfirm")}
            className="editNumber"
          >
            EDIT NUMBER
          </a>
        </div>
      </div>
    </div>
  );
};
export default OtpConfirm;
