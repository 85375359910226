import { ToastsStore } from "react-toasts";
var toastLimit = false;

String.prototype.formatUnicorn =
  String.prototype.formatUnicorn ||
  function () {
    var string = this.toString();

    if (arguments.length) {
      var type = typeof arguments[0];

      var key;

      var args =
        "string" === type || "number" === type
          ? Array.prototype.slice.call(arguments)
          : arguments[0];

      for (key in args) {
        string = string.replace(
          new RegExp("\\{" + key + "\\}", "gi"),
          args[key]
        );
      }
    }

    return string;
  };
/*eslint no-extend-native: "off"*/

export const mobileNumberRegex = (event) => {
  let value = event && event.replace(/\d(?=\d{4})/g, "*");
  return value;
};

export const checkAccordionOpen = (event) => {
  if (event === true) {
    return false;
  } else if (event === false) {
    return true;
  }
};

export const DecimalConversion = (value, digits) => {
  return value && value.toFixed(digits);
};
export const RemoveDecimalFromString = (str) => str && str.split(".").shift();

export const CommaSeperator = (number) =>
  number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const ToastMessageHandler = (msg) => {
  if (!toastLimit && msg) {
    ToastsStore.success(msg);
    toastLimit = true;
    setTimeout(() => {
      toastLimit = false;
    }, 3000);
  }
};
