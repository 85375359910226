import React, { useState, useEffect } from "react";
import "./Payment.scss";
import razorpayIcon from "../../assets/img/razorpayLogo.svg";
import phonePe from "../../assets/img/phonepe.svg";
import backArrow from "../../assets/img/baseline_arrow.svg";
import qrcode from "../../assets/img/qrcode.svg";
import MainHeader from "../../reusableComponents/MainHeader";
import { repaymentInitiate } from "../../redux/actions/RepaymentInitiate";
import { connect } from "react-redux";
import { RAZOR_PAY_CODE, PHONE_PE_CODE } from "../../utils/Constants";
import { baseURL } from "../../redux/utils/ApiRequestPaths";
import axios from "axios";
import { tokenConfig } from "../../redux/utils/Configuration";
// import { DefaultLoading } from "@capfront-technologies/loanfront-ui-components";
import { DefaultLoading } from "../Container/Loader/LoaderScreen";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

function Payment(props) {
  const { t } = useTranslation();
  const loanId =
    sessionStorage.getItem("loanId") == null
      ? "0"
      : sessionStorage.getItem("loanId");
  const lfi =
    sessionStorage.getItem("LFI") == null ? "0" : sessionStorage.getItem("LFI");
  const lfiText =
    lfi == "1"
      ? `${lfi}st installment`
      : lfi == "2"
      ? `${lfi}nd installment`
      : lfi == "3"
      ? `${lfi}rd installment`
      : `${lfi}th installment`;
  let lfiAmount =
    sessionStorage.getItem("LFI_Amount") == null
      ? "0"
      : sessionStorage.getItem("LFI_Amount");
  const interestAmt =
    sessionStorage.getItem("interest_Amount") == null
      ? "0"
      : sessionStorage.getItem("interest_Amount");
  const repayAmount =
    sessionStorage.getItem("loanAmount") == null
      ? "0"
      : sessionStorage.getItem("loanAmount");
  const repayText = sessionStorage.getItem("repaymentAmount")
    ? sessionStorage.getItem("repaymentAmount")
    : "Pay";
  const [selected, setSelected] = useState({
    razorpay: false,
    qrcode: false,
    phonePe: false,
  });
  const [api_enable, setapi_enabled] = React.useState(false);
  const [callbackCalled, setCallbackCalled] = useState(false);

  const selectPaymentMethod = (target) => {
    if (target === "rp") {
      setSelected({
        razorpay: true,
        qrcode: false,
        phonePe: false,
      });
    }
    if (target === "qr") {
      setSelected({
        razorpay: false,
        qrcode: true,
        phonePe: false,
      });
    }
    if (target === "phonepe") {
      setSelected({
        razorpay: false,
        qrcode: false,
        phonePe: true,
      });
    }
  };

  const goBack = () => {
    props.ChangeActiveComp("activeLoan");
  };

  const payNow = () => {
    if (selected.razorpay) {
      callPaymentInitiate(RAZOR_PAY_CODE);
    }
    if (selected.qrcode) {
      return props.ChangeActiveComp("upiPay");
    }
    if (selected.phonePe) {
      callPaymentInitiate(PHONE_PE_CODE);
    }
  };

  const callPaymentInitiate = (target) => {
    setapi_enabled(true);
    props.repaymentInitiate(
      sessionStorage.getItem("loanId"),
      target,
      sessionStorage.getItem("loanScheduleId"),
      sessionStorage.getItem("couponId")
    );
  };

  React.useEffect(() => {
    if (
      api_enable &&
      props.repaymentInitiateResult &&
      props.repaymentInitiateResult
    ) {
      if (props.repaymentInitiateResult.status === "OK") {
        let data =
          props.repaymentInitiateResult &&
          props.repaymentInitiateResult.results &&
          props.repaymentInitiateResult.results[0].properties;
        sessionStorage.setItem("order_amount", data && data.transaction_amt);
        if (selected.razorpay) {
          openCheckout(data && data);
        }
        if (selected.phonePe) {
          sessionStorage.setItem("repayTransId", data.repayment_transaction_id);
          sessionStorage.setItem("merchantTransId", data.merchantTranId);
          window.location.replace(data && data.phonepe_url && data.phonepe_url);
        }
      }
      if (props.repaymentInitiateResult.status === "UNAUTHORIZED") {
        return props.ChangeActiveComp("login");
      }
      setapi_enabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.repaymentInitiateResult]);

  useEffect(() => {
    if (sessionStorage.getItem("lender") === "YUBI_MFL_100") {
      selectPaymentMethod("rp");
    }
    if (sessionStorage.getItem("lender") === "LF_BCPL_100") {
      selectPaymentMethod("phonepe");
    }
  }, []);

  const openCheckout = (response) => {
    let options = {
      key: response.rp_key,
      amount: response.rp_amount, // 2000 paise = INR 20, amount in paisa
      name: "LoanFront",
      description: "LoanID: " + response.loan_id,
      image:
        "https://d33h20yh3dmq28.cloudfront.net/images/LF Logo with tagline.png",
      currency: response.rp_currency,
      order_id: response.rp_order_id,
      handler: function (res) {
        callTransactionSuccess(res, response.repayment_transaction_id);
      },
      prefill: {
        email: response.buyer_email,
        contact:
          sessionStorage.getItem("mobile_number") &&
          sessionStorage.getItem("mobile_number"),
      },
    };
    let rzp = new window.Razorpay(options);
    rzp.open();
    rzp.on("payment.failed", () => {});
  };

  const callTransactionSuccess = (response, transID) => {
    const data = {
      properties: {
        rp_payment_id: response.razorpay_payment_id,
        repayment_transaction_id: transID,
        loan_id:
          sessionStorage.getItem("loanId") && sessionStorage.getItem("loanId"),
      },
    };
    setCallbackCalled(true);
    axios
      .post(
        `${baseURL}/payment_gateway/razorpay/callback?`,
        data,
        tokenConfig()
      )
      .then((res) => {
        setCallbackCalled(false);
        if (sessionStorage.getItem("schedulesPending") === "1") {
          props.ChangeActiveComp("finalSuccess", "");
        } else {
          props.ChangeActiveComp("success", "");
        }
      })
      .catch((err) => {
        setCallbackCalled(false);
      });
  };

  useEffect(() => {
    if (
      api_enable &&
      props.repaymentInitiateResultError &&
      props.repaymentInitiateResultError.message
    ) {
      setapi_enabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.repaymentInitiateResultError]);

  return (
    <>
      {callbackCalled ? (
        <DefaultLoading />
      ) : (
        // <div className="loginMainDiv">
        <div className="paymentSubDiv">
          <MainHeader target="payment" onclick={props.ChangeActiveComp} />
          <div className="padDiv">
            <div className="arrowDiv" onClick={goBack}>
              <img src={backArrow} /> Go Back
            </div>
            <p className="paymentText">Loan Repayment</p>
            <div className="paymentDetailsDiv">
              <div className="subDetailsDiv">
                <p>Loan ID</p>
                <p>{loanId}</p>
              </div>
              <div className="subDetailsDiv">
                <p>LFI</p>
                <p>{lfiText}</p>
              </div>
              <div className="subDetailsDiv">
                <p>LFI Amount</p>
                <p>{lfiAmount}</p>
              </div>

              <div className="subDetailsDiv">
                <p>Interest Amount</p>
                <p>{interestAmt}</p>
              </div>
              {sessionStorage.getItem("penalityAmount") && (
                <div className="subDetailsDiv">
                  <p>Late Payment Fee</p>
                  <p>{sessionStorage.getItem("penalityAmount")}</p>
                </div>
              )}
              {sessionStorage.getItem("adjustAmount") && (
                <div className="subDetailsDiv">
                  <p>Adjusted Amount</p>
                  <p>{sessionStorage.getItem("adjustAmount").split("₹")[1]}</p>
                </div>
              )}
              <div className="subDetailsDiv">
                <p>Repayment Amount</p>
                <p>{repayAmount}</p>
              </div>
            </div>
            <p className="paymentMethodString">Select your payment mode:</p>
            {sessionStorage.getItem("lender") === "YUBI_MFL_100" ? (
              <>
                <button
                  className={
                    selected.razorpay
                      ? "selectedCls razorpayBtn mt-3"
                      : "razorpayBtn mt-3"
                  }
                  onClick={() => selectPaymentMethod("rp")}
                >
                  <img className="razorpayimg" src={razorpayIcon} /> Razor pay
                </button>
                <span className="convTextGateway">
                  Convenience fee may charged by payment gateway
                </span>
              </>
            ) : sessionStorage.getItem("lender") === "LF_BCPL_100" ||
              sessionStorage.getItem("lender") === "PERFIOS_BCPL_100" ? (
              <>
                <>
                  {/* <button
                    className={
                      selected.phonePe
                        ? "selectedCls razorpayBtn mt-3"
                        : "razorpayBtn mt-3"
                    }
                    onClick={() => selectPaymentMethod("phonepe")}
                  >
                    <img className="razorpayimg" src={phonePe} /> Phone Pe
                  </button>
                  <span className="convTextGateway">
                    Convenience fee may charged by payment gateway
                  </span> */}
                  <button
                    className={
                      selected.qrcode
                        ? "selectedCls razorpayBtn"
                        : "razorpayBtn"
                    }
                    onClick={() => selectPaymentMethod("qr")}
                  >
                    <img className="qrcode" src={qrcode} /> UPI QR
                  </button>
                  <span className="convText">
                    [ZERO / NO convenience fee charged]
                  </span>
                  {localStorage.getItem("permissionError") === "true" && (
                    <span className="QrPermissionError">
                      <strong>Note:</strong> Please provide the notification
                      permission in the browser settings to proceed with QR code
                      payment.
                    </span>
                  )}
                </>

                {/* <>
                  <button
                    className={
                      selected.razorpay
                        ? "selectedCls razorpayBtn mt-3"
                        : "razorpayBtn mt-3"
                    }
                    onClick={() => selectPaymentMethod("rp")}
                  >
                    <img className="razorpayimg" src={razorpayIcon} /> Razor pay
                  </button>
                  <span className="convTextGateway">
                    Convenience fee may charged by payment gateway
                  </span>
                </> */}
              </>
            ) : (
              <>
                <button
                  className={
                    selected.qrcode ? "selectedCls razorpayBtn" : "razorpayBtn"
                  }
                  onClick={() => selectPaymentMethod("qr")}
                >
                  <img className="qrcode" src={qrcode} /> UPI QR
                </button>
                <span className="convText">
                  [ZERO / NO convenience fee charged]
                </span>
                {localStorage.getItem("permissionError") === "true" && (
                  <span className="QrPermissionError">
                    <strong>Note:</strong> Please provide the notification
                    permission in the browser settings to proceed with QR code
                    payment.
                  </span>
                )}
                <button
                  className={
                    selected.razorpay
                      ? "selectedCls razorpayBtn mt-3"
                      : "razorpayBtn mt-3"
                  }
                  onClick={() => selectPaymentMethod("rp")}
                >
                  <img className="razorpayimg" src={razorpayIcon} /> Razor pay
                </button>
                <span className="convTextGateway">
                  Convenience fee may charged by payment gateway
                </span>
              </>
            )}

            <button
              className="paybtn mt-3"
              disabled={
                selected.qrcode || selected.razorpay || selected.phonePe
                  ? false
                  : true
              }
              onClick={payNow}
            >
              {t("payNowString").formatUnicorn(repayText)}
            </button>
          </div>
        </div>
        // </div>
      )}
    </>
  );
}

const mapState = (state) => {
  return {
    isRepaymentIntiateLoading: state.RepaymentInitiate.isLoading,
    repaymentInitiateResult:
      state.RepaymentInitiate && state.RepaymentInitiate.data,
    repaymentInitiateResultError:
      state.RepaymentInitiate && state.RepaymentInitiate.data,
  };
};
const mapDispatch = {
  repaymentInitiate,
};

export default connect(mapState, mapDispatch)(Payment);
