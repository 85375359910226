import React from "react";
import { useEffect } from "react";
import "./ConfirmDeleteAccount.scss";
import axios from "axios";
import { OK } from "../../utils/Constants";
import { useState } from "react";
import { baseURL } from "../../redux/utils/ApiRequestPaths";

function ConfirmDeleteAccount(props) {
  const { ChangeActiveComp } = props;
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [notRegError, setNotRegError] = useState(false);

  const backToLoginScreen = () => {
    ChangeActiveComp("userAuth", "");
  };
  const goToOtpScreen = () => {
    const data = {
      properties: {
        phone_number: sessionStorage.getItem("mobile_number"),
      },
    };
    setLoading(true);
    axios
      .post(`${baseURL}/lf/delete_account?type=send_otp`, data)
      .then((res) => {
        setLoading(false);
        console.log("otpRes:", res);
        if (res.status === 200) {
          if (res.data) {
            if (res.data.status) {
              return res.data.status === "OK"
                ? ChangeActiveComp("userOtpConfirm", "")
                : setNotRegError(true);
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              setApiMessage(err.response.data.message);
              setNotRegError(true);
            } else {
              setApiMessage("Something went wrong");
              setNotRegError(true);
            }
          }
        }
      });
  };
  return (
    <div
      className="deleteLoginMainDiv heightDiv mobileViewHeight"
      // style={{ backgroundColor: "white", color: "white", height: "90%" }}
    >
      <div className="parentContainer">
        <div className="firstChildDiv">
          <p className="delete__text">Delete your LoanFront account</p>
          <div className="confirm__text">
            <p className="mb-3">
              Please note: Once your account is deleted, it cannot be restored
            </p>
            <p className="mb-3">
              We highly advise keeping your LoanFront account as it enables you
              to easily apply for loans and receive great offers through the
              LoanFront app. Additionally, it provides the convenience of
              regular monitoring of your credit score
            </p>
            <div className="listDiv">
              <p>If you delete your account:</p>
              <ul>
                <li>
                  You will no longer have immediate access to your loan history
                  on the LoanFront app.
                </li>
                <li>
                  You will be unable to utilize the LoanFront app for loan
                  applications.
                </li>
                <li>
                  You will not be able to retrieve the credit reports you have
                  purchased.
                </li>
              </ul>
            </div>
            <p className="mb-3">
              Please take a moment to familiarize yourself with our{" "}
              <a
                href="https://www.loanfront.in/privacy-policy.html"
                target="_blank"
              >
                Privacy Policy,
              </a>{" "}
              <a
                href="https://www.loanfront.in/privacy-policy.html"
                target="_blank"
              >
                Record and Data Retention Policy
              </a>
              . In case you need furthur assistance, Please feel free to reach out to
              us at{" "}
              <a href="mailto:support@loanfront.in" target="_blank">
                support@loanfront.in
              </a>
            </p>
          </div>
          <p className="confirm__text">
            Are you sure you want to delete your account?
          </p>
        </div>
        {notRegError && <span className="errorMessage">{apiMessage}</span>}
        <div style={{ marginBottom: "28px" }}>
          <button className="customBtnVvpl" onClick={backToLoginScreen}>
            CANCEL
          </button>
          <button className="button__Styling" onClick={goToOtpScreen}>
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeleteAccount;
