import React, { useState, useEffect } from "react";
import Login from "../Login/Login";
import VerifyOtp from "../Otp/otp";
import ActiveHomeLoan from "../activeHomeLoan/ActiveHomeLoan";
import { connect, Provider } from "react-redux";
import { baseURL } from "../../redux/utils/ApiRequestPaths";
import axios from "axios";
import Fail from "../Fail/Fail";
import Success from "../Success/Success";
import Download from "../activeHomeLoan/download";
import Home from "./Home";
import "./Container.scss";
import vvplLogo from "../../assets/img/vvplLogo.svg";
import LoanfrontLogo from "../../assets/img/LoanfrontLogo.svg";
import styleIconLeft from "../../assets/img/styleIconLeft.svg";
import styleIconRight from "../../assets/img/styleIconRight.svg";
import UpiPay from "../upiPay/upiPay";
import Payment from "../Payment/Payment";
import { clearLoanhistory } from "../../redux/actions/LoanHistory";
import { clearAccountDetails } from "../../redux/actions/AccountDetails";
import { clearProfileDetails } from "../../redux/actions/ProfileDetails";
import LoanClosed from "../Success/LoanClosed";

function Container(props) {
  const [activeComp, setActiveComp] = useState({
    login: true,
    verifyOtp: false,
    activeLoan: false,
    success: false,
    fail: false,
    download: false,
    paymentScreen: false,
    upiPay: false,
    finalSuccess: false,
  });

  const [activepage, setActivepage] = useState({
    home: true,
    downloadApp: false,
  });

  const ChangeActiveComp = (target, checkComp) => {
    const oldState = { ...activeComp };
    Object.keys(oldState).map((el) => {
      if (el === target) {
        return (oldState[el] = true);
      } else {
        return (oldState[el] = false);
      }
    });
    setActiveComp(oldState);
    if (target === "login" && checkComp !== "otp") {
      const savehost = sessionStorage.getItem("host");
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem("host", savehost);
      props.clearLoanhistory();
      props.clearProfileDetails();
      props.clearAccountDetails();
    }
    return;
  };

  const callResendOTP = async () => {
    const number = sessionStorage.getItem("mobile_number");
    if (number) {
      const data = {
        properties: {
          phone_number: number,
          scope: "customer",
        },
      };
      await axios
        .post(`${baseURL}/phone/login?v=3006`, data)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              if (res.data.status) {
                if (res.data.status === "OK") {
                  sessionStorage.setItem(
                    "mobile_token",
                    res.data.results && res.data.results.mobile_token
                  );
                }
              }
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data) {
              if (err.response.data.message) {
                return err.response.data.message;
              } else {
                return "Please login with the registered mobile number";
              }
            }
          }
        });
    } else {
      return false;
    }
  };

  useEffect(() => {
    let token, userId, loanClosed, repaymentSuccess, repaymentFail;
    token = sessionStorage.getItem("_ta");
    userId = sessionStorage.getItem("userId");
    loanClosed = sessionStorage.getItem("phonepeFinalSuccess");
    repaymentSuccess = sessionStorage.getItem("phonepeSuccess");
    repaymentFail = sessionStorage.getItem("phonepeFail");

    if (loanClosed) {
      return ChangeActiveComp("finalSuccess");
    }
    if (repaymentSuccess) {
      return ChangeActiveComp("success");
    }
    if (repaymentFail) {
      return ChangeActiveComp("fail");
    }
    if (token && userId) {
      return ChangeActiveComp("activeLoan");
    }
  }, []);

  return (
    <div className="contmainDiv">
      {activepage.home && (
        <>
          <div className="left">
            <Home login={activeComp} ChangeActiveComp={ChangeActiveComp} />
          </div>
          <div className="right">
            {activeComp.login && <Login ChangeActiveComp={ChangeActiveComp} />}
            {activeComp.verifyOtp && (
              <VerifyOtp
                ChangeActiveComp={ChangeActiveComp}
                callResendOTP={callResendOTP}
              />
            )}
            {activeComp.activeLoan && (
              <ActiveHomeLoan ChangeActiveComp={ChangeActiveComp} />
            )}
            {activeComp.paymentScreen && (
              <Payment ChangeActiveComp={ChangeActiveComp} />
            )}
            {activeComp.success && (
              <Success ChangeActiveComp={ChangeActiveComp} />
            )}
            {activeComp.fail && <Fail ChangeActiveComp={ChangeActiveComp} />}
            {activeComp.download && (
              <Download ChangeActiveComp={ChangeActiveComp} />
            )}
            {activeComp.upiPay && (
              <UpiPay ChangeActiveComp={ChangeActiveComp} />
            )}
            {activeComp.finalSuccess && (
              <LoanClosed ChangeActiveComp={ChangeActiveComp} />
            )}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = () => {};
const mapDispatchToProps = {
  clearLoanhistory,
  clearAccountDetails,
  clearProfileDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
