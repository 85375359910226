import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { connect } from "react-redux";
import { homePageApiProducts } from "../../../redux/actions/homePageApiProducts";
import Moment from "moment";
import "../HomepageProductsList/WebProductsListHome.css";
import LockedProduct from "../../../assets/img/lockicon.svg";
import Tooltip from "../Tooltip";

var x = "";
var y = "";

class ProductsListHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setActive: {},
      product_list_result: "",
      product_list_result_products: "",
      product_list_result_products_tenureDays: "",
      product_list_result_products_schedules: "",

      selectedItem: "",
      selectedProductTypeId: "",
      selectedPrincipalAmount: "",
      schedule_card_schedle_ui_display: false,
      disable_loandetails_field: false,

      button_sches: [],
      tenure_schedules_array: [],
      api_res_react: [],
      tenure_schedule_prop: "",
      toggle: false,
    };
    this.handleDisplayItems = this.handleDisplayItems.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.accordion_function = this.accordion_function.bind(this);
  }
  componentDidMount() {
    const res = this.props.apires;
    if (
      res &&
      res.results &&
      res.results.products &&
      res.results.context === "product"
    ) {
      x = res.results.products.sort((a, b) => b.amount - a.amount);
      var newData = x.map((product) => {
        y = product.tenures.sort((a, b) => b.days - a.days);
        product.isOpened = false;
        y.map((tenure, i) => {
          tenure.schedules.map((schedule) => {
            if (schedule.properties.is_locked === 1) {
              product.isLocked = true;
            } else {
              product.isLocked = false;
            }
            if (i == 0) {
              schedule.properties.isSelected = true;
            } else {
              schedule.properties.isSelected = false;
            }
            return schedule;
          });
          return tenure;
        });
        return res;
      });
      var removedJsonArray = { ...newData[0] };
      this.setState({ api_res_react: removedJsonArray });
    } else {
    }
  }

  onProductSelected(productTypeId, selectedAmount) {
    localStorage.setItem("productTypeId", productTypeId);
    if (window.Android)
      window.Android.productSelected(
        productTypeId,
        selectedAmount,
        JSON.stringify(this.state.api_res_react)
      );
  }

  handleChange(e, loanDuration, loanProductTypeId, PrincipalAmount, idex_btn) {
    var stateValue = this.state.api_res_react;
    var newData = stateValue.results.products.map((product) => {
      product.tenures.map((tenure) => {
        tenure.schedules.map((schedule) => {
          if (schedule.properties.principal_amt === PrincipalAmount) {
            if (
              schedule.properties.product_type_id === loanProductTypeId &&
              schedule.properties.isSelected === false
            ) {
              schedule.properties.isSelected = true;
            } else {
              schedule.properties.isSelected = false;
            }
          }
          return schedule;
        });
        return tenure;
      });
      return stateValue;
    });

    var removedJsonArray = { ...newData[0] };
    this.setState({
      api_res_react: removedJsonArray,
      selectedItem: loanDuration,
      selectedProductTypeId: loanProductTypeId,
      selectedPrincipalAmount: PrincipalAmount,
      schedule_card_schedle_ui_display: true,
    });
  }

  accordion_function(amount, tenures) {
    var isLocked = tenures.map((tenure) =>
      tenure.schedules.map((schedule) => schedule.properties.is_locked)
    );
    if (isLocked == 1) {
      if (window.Android)
        window.Android.lockedProductSelected(
          amount,
          JSON.stringify(this.state.api_res_react)
        );
    } else {
      var stateValue = this.state.api_res_react;
      var newData = stateValue.results.products.map((product) => {
        if (product.amount === amount) {
          if (product.isOpened === false) {
            product.isOpened = true;
          } else {
            product.isOpened = false;
          }
        }
        return stateValue;
      });

      var removedJsonArray = { ...newData[0] };

      this.setState({
        api_res_react: removedJsonArray,
      });
    }
  }

  handleDisplayItems(
    loanDuration,
    loanProductTypeId,
    PrincipalAmount,
    idex_btn
  ) {
    this.setState({
      selectedItem: loanDuration,
      selectedProductTypeId: loanProductTypeId,
      selectedPrincipalAmount: PrincipalAmount,
      schedule_card_schedle_ui_display: true,
    });
    if (this.state.selectedPrincipalAmount !== "") {
      if (PrincipalAmount !== this.state.selectedPrincipalAmount) {
        this.setState({ disable_loandetails_field: true });
      } else {
        this.setState({ disable_loandetails_field: false });
      }
    }
    this.setState({
      [`${loanProductTypeId}-${loanDuration}`]: !this.state.toggle,
    });
  }

  render() {
    document.body.style.overflow = "auto";

    return (
      <React.Fragment>
        {x && x.length === 1
          ? x.map((item, i) => (
              <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                className="bcd"
                onClick={(e) =>
                  this.accordion_function(item.amount, item.tenures)
                }
              >
                <AccordionItem className="abc">
                  <AccordionItemButton className="cde">
                    {item.tenures &&
                      item.tenures.map((obj) =>
                        obj.schedules.map((schedule) =>
                          schedule.properties.isSelected === true ? (
                            <div className="product_list_left">
                              <h1
                                style={{
                                  color:
                                    schedule.properties.tenure_days === 62 ||
                                    schedule.properties.tenure_days === 63
                                      ? "#2aad62"
                                      : schedule.properties.tenure_days === 15
                                      ? "#eebf31"
                                      : "#1d2a78",
                                }}
                              >
                                ₹{item.amount.toLocaleString()}
                              </h1>
                              {item.isOpened === true ? (
                                ""
                              ) : schedule.properties.is_locked === 1 ? (
                                <div className="product_locked_card">
                                  <h6>
                                    {"₹" +
                                      Math.max.apply(
                                        Math,
                                        item.tenures.map((objq) =>
                                          objq.schedules.map(
                                            (objhy) =>
                                              objhy.properties.interest_amount
                                          )
                                        )
                                      ) +
                                      " interest"}
                                  </h6>
                                  <img
                                    src={LockedProduct}
                                    alt="LockedProduct"
                                  />
                                </div>
                              ) : (
                                <h6>
                                  {"₹" +
                                    Math.max.apply(
                                      Math,
                                      item.tenures.map((objq) =>
                                        objq.schedules.map(
                                          (objhy) =>
                                            objhy.properties.interest_amount
                                        )
                                      )
                                    ) +
                                    " interest"}
                                </h6>
                              )}
                            </div>
                          ) : (
                            ""
                          )
                        )
                      )}

                    {item.tenures &&
                      item.tenures.map((obj) =>
                        obj.schedules.map((schedule) =>
                          schedule.properties.isSelected === true ? (
                            <div
                              className="product_list_right"
                              style={{
                                backgroundColor:
                                  schedule.properties.tenure_days === 62 ||
                                  schedule.properties.tenure_days === 63
                                    ? "#2aad62"
                                    : schedule.properties.tenure_days === 15
                                    ? "#eebf31"
                                    : "#1d2a78",
                              }}
                            >
                              <h1
                                style={{
                                  backgroundColor:
                                    schedule.properties.tenure_days === 62 ||
                                    schedule.properties.tenure_days === 63
                                      ? "#2aad62"
                                      : schedule.properties.tenure_days === 15
                                      ? "#eebf31"
                                      : "#1d2a78",
                                }}
                              >
                                {schedule.properties.tenure_days}
                              </h1>
                              <h6
                                style={{
                                  backgroundColor:
                                    schedule.properties.tenure_days === 62 ||
                                    schedule.properties.tenure_days === 63
                                      ? "#2aad62"
                                      : schedule.properties.tenure_days === 15
                                      ? "#eebf31"
                                      : "#1d2a78",
                                }}
                              >
                                Days
                              </h6>
                            </div>
                          ) : (
                            ""
                          )
                        )
                      )}
                  </AccordionItemButton>

                  <AccordionItemPanel
                    className="abdcde"
                    style={{
                      display:
                        item.tenures &&
                        item.tenures.map((obj) =>
                          obj.schedules.map((schedule) =>
                            schedule.properties.is_locked === 1 ? "none" : ""
                          )
                        ),
                    }}
                  >
                    {/* <div className='panel_open'>  */}
                    <div>
                      {item.tenures &&
                        item.tenures.map((obj) =>
                          obj.schedules.map((schedule) =>
                            schedule.properties.isSelected === true ? (
                              <p
                                className="panel_open"
                                style={{
                                  borderTopColor:
                                    schedule.properties.tenure_days === 62 ||
                                    schedule.properties.tenure_days === 63
                                      ? "#2aad62"
                                      : schedule.properties.tenure_days === 15
                                      ? "#eebf31"
                                      : "#1d2a78",
                                }}
                              ></p>
                            ) : (
                              ""
                            )
                          )
                        )}

                      <p className="product_list_choose_nbfc_p">
                        Choose Tenure Days
                      </p>
                      <form class="boxed">
                        {item.tenures.map((obj_sche) =>
                          obj_sche.schedules.map((obj, idex_btn) =>
                            item.amount === obj.properties.principal_amt ? (
                              <div className="boxed_div">
                                <input
                                  type="radio"
                                  id={obj.properties.product_type_id}
                                  name="skills"
                                  value={obj.properties.product_type_id}
                                  defaultChecked={
                                    obj.properties.tenure_days ===
                                      Math.max.apply(
                                        Math,
                                        item.tenures.map((objq) =>
                                          objq.schedules.map(
                                            (objhy) =>
                                              objhy.properties.tenure_days
                                          )
                                        )
                                      ) && this.state.selectedItem === ""
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this.handleChange(
                                      e,
                                      obj.properties.tenure_days,
                                      obj.properties.product_type_id,
                                      obj.properties.principal_amt,
                                      idex_btn
                                    )
                                  }
                                />
                                <label for={obj.properties.product_type_id}>
                                  {" "}
                                  {obj.properties.tenure_days}{" "}
                                </label>
                              </div>
                            ) : (
                              ""
                            )
                          )
                        )}
                      </form>

                      {item.tenures.map((obj_sche) =>
                        obj_sche.schedules.map(
                          (obj_schedule) =>
                            obj_schedule.properties.schedules &&
                            obj_schedule.properties.schedules.map(
                              (obj_schedule_card, index) =>
                                obj_schedule.properties.tenure_days ===
                                  Math.max.apply(
                                    Math,
                                    item.tenures.map((objq) =>
                                      objq.schedules.map(
                                        (objhy) => objhy.properties.tenure_days
                                      )
                                    )
                                  ) && this.state.selectedItem === "" ? (
                                  <div>
                                    <div className="cde_schedule_card">
                                      <div className="product_list_schedule_details_firstdiv">
                                        <h6>
                                          LFI:{obj_schedule_card.schedule_num}
                                        </h6>
                                      </div>

                                      <div className="product_list_schedule_details_seconddiv">
                                        <h1>
                                          ₹
                                          {obj_schedule_card.due_amount.toLocaleString()}
                                        </h1>
                                        <h6>
                                          DUE ON{" "}
                                          {Moment()
                                            .add(
                                              obj_schedule_card.schedule_days -
                                                1,
                                              "d"
                                            )
                                            .format("DD-MM-yyyy")}
                                        </h6>
                                      </div>

                                      <div className="product_list_schedule_details_thirddiv">
                                        <h6>DUE IN</h6>
                                        <h1>
                                          {obj_schedule_card.schedule_days}
                                        </h1>
                                        <h6>Days</h6>
                                      </div>
                                    </div>
                                    {index ===
                                    obj_schedule.properties.schedules.length -
                                      1 ? (
                                      ""
                                    ) : (
                                      <div className="vertical_hr_schedules"></div>
                                    )}
                                  </div>
                                ) : obj_schedule.properties.isSelected ===
                                  true ? (
                                  <div>
                                    <div className="cde_schedule_card">
                                      <div className="product_list_schedule_details_firstdiv">
                                        <h6>
                                          LFI:{obj_schedule_card.schedule_num}
                                        </h6>
                                      </div>

                                      <div className="product_list_schedule_details_seconddiv">
                                        <h1>₹{obj_schedule_card.due_amount}</h1>
                                        <h6>
                                          DUE ON{" "}
                                          {Moment()
                                            .add(
                                              obj_schedule_card.schedule_days -
                                                1,
                                              "d"
                                            )
                                            .format("DD-MM-yyyy")}
                                        </h6>
                                      </div>

                                      <div className="product_list_schedule_details_thirddiv">
                                        <h6>DUE IN</h6>
                                        <h1>
                                          {obj_schedule_card.schedule_days}
                                        </h1>
                                        <h6>Days</h6>
                                      </div>
                                    </div>
                                    {index ===
                                    obj_schedule.properties.schedules.length -
                                      1 ? (
                                      ""
                                    ) : (
                                      <div className="vertical_hr_schedules"></div>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )
                            )
                        )
                      )}

                      {item.tenures.map((tenure) =>
                        tenure.schedules.map((schedule) =>
                          schedule.properties.isSelected === true
                            ? schedule.properties.loan_details.map(
                                (obj_loan_details) => (
                                  <div className="dynamic_ui">
                                    {obj_loan_details.title ===
                                    "dividerValue" ? (
                                      <hr
                                        style={{
                                          color: obj_loan_details.color,
                                          marginTop: "8px",
                                          marginRight: "10px",
                                        }}
                                      ></hr>
                                    ) : (
                                      <div className="dynamic_ui_flex">
                                        <div className="dynamic_ui_flex_left">
                                          <p style={{ color: "#1c1c1c" }}>
                                            {obj_loan_details.title}
                                            <br />
                                          </p>
                                          <span style={{ color: "#1c1c1c" }}>
                                            {obj_loan_details.subTitle &&
                                              obj_loan_details.subTitle}
                                          </span>
                                        </div>
                                        <div className="dynamic_ui_flex_right">
                                          <p
                                            style={{
                                              color: obj_loan_details.color,
                                              letterSpacing: "1px",
                                            }}
                                          >
                                            {obj_loan_details.toolTipText ? (
                                              <Tooltip
                                                toolTipText={
                                                  obj_loan_details.toolTipText
                                                }
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {obj_loan_details.description}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              )
                            : ""
                        )
                      )}

                      {item.tenures.map((tenure) =>
                        tenure.schedules.map((schedule) =>
                          schedule.properties.isSelected === true ? (
                            <div>
                              <a
                                href="https://play.google.com/store/apps/details?id=in.loanfront.android&hl=en"
                                target="_blank"
                              >
                                {" "}
                                <button className="verifyGetLoanButton">
                                  GET LOAN FROM APP
                                </button>
                              </a>
                            </div>
                          ) : (
                            ""
                          )
                        )
                      )}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            ))
          : this.state.api_res_react &&
            this.state.api_res_react.results &&
            this.state.api_res_react.results.products &&
            this.state.api_res_react.results.context === "product" &&
            this.state.api_res_react.results.products.map((item, i) =>
              item.isLocked === false ? (
                <Accordion
                  allowMultipleExpanded
                  allowZeroExpanded
                  className="bcd"
                  onClick={(e) =>
                    this.accordion_function(item.amount, item.tenures)
                  }
                >
                  <AccordionItem className="abc">
                    <AccordionItemButton className="cde">
                      {item.tenures &&
                        item.tenures.map((obj) =>
                          obj.schedules.map((schedule) =>
                            schedule.properties.isSelected === true ? (
                              <div className="product_list_left">
                                <h1
                                  style={{
                                    color:
                                      schedule.properties.tenure_days === 62 ||
                                      schedule.properties.tenure_days === 63
                                        ? "#2aad62"
                                        : schedule.properties.tenure_days === 15
                                        ? "#eebf31"
                                        : "#1d2a78",
                                  }}
                                >
                                  ₹{item.amount.toLocaleString()}
                                </h1>
                                <AccordionItemState>
                                  {({ expanded }) =>
                                    expanded ? (
                                      " "
                                    ) : (
                                      <h6 id="interest">
                                        {"₹" +
                                          Math.max.apply(
                                            Math,
                                            item.tenures.map((objq) =>
                                              objq.schedules.map(
                                                (objhy) =>
                                                  objhy.properties
                                                    .interest_amount
                                              )
                                            )
                                          ) +
                                          " interest"}
                                      </h6>
                                    )
                                  }
                                </AccordionItemState>

                                {item.isOpened === true ? (
                                  ""
                                ) : schedule.properties.is_locked === 1 ? (
                                  <div className="product_locked_card">
                                    <img
                                      src={LockedProduct}
                                      alt="LockedProduct"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )
                          )
                        )}

                      {item.tenures &&
                        item.tenures.map((obj) =>
                          obj.schedules.map((schedule) =>
                            schedule.properties.isSelected === true ? (
                              <div
                                className="product_list_right"
                                style={{
                                  backgroundColor:
                                    schedule.properties.tenure_days === 62 ||
                                    schedule.properties.tenure_days === 63
                                      ? "#2aad62"
                                      : schedule.properties.tenure_days === 15
                                      ? "#eebf31"
                                      : "#1d2a78",
                                }}
                              >
                                <h1
                                  style={{
                                    backgroundColor:
                                      schedule.properties.tenure_days === 62 ||
                                      schedule.properties.tenure_days === 63
                                        ? "#2aad62"
                                        : schedule.properties.tenure_days === 15
                                        ? "#eebf31"
                                        : "#1d2a78",
                                  }}
                                >
                                  {schedule.properties.tenure_days}
                                </h1>
                                <h6
                                  style={{
                                    backgroundColor:
                                      schedule.properties.tenure_days === 62 ||
                                      schedule.properties.tenure_days === 63
                                        ? "#2aad62"
                                        : schedule.properties.tenure_days === 15
                                        ? "#eebf31"
                                        : "#1d2a78",
                                  }}
                                >
                                  Days
                                </h6>
                              </div>
                            ) : (
                              ""
                            )
                          )
                        )}
                    </AccordionItemButton>

                    <AccordionItemPanel
                      className="abdcde"
                      style={{
                        display:
                          item.tenures &&
                          item.tenures.map((obj) =>
                            obj.schedules.map((schedule) =>
                              schedule.properties.is_locked === 1 ? "none" : ""
                            )
                          ),
                      }}
                    >
                      {/* <div className='panel_open'>  */}
                      <div>
                        {item.tenures &&
                          item.tenures.map((obj) =>
                            obj.schedules.map((schedule) =>
                              schedule.properties.isSelected === true ? (
                                <p
                                  className="panel_open"
                                  style={{
                                    borderTopColor:
                                      schedule.properties.tenure_days === 62 ||
                                      schedule.properties.tenure_days === 63
                                        ? "#2aad62"
                                        : schedule.properties.tenure_days === 15
                                        ? "#eebf31"
                                        : "#1d2a78",
                                  }}
                                ></p>
                              ) : (
                                ""
                              )
                            )
                          )}

                        <p className="product_list_choose_nbfc_p">
                          Choose Tenure Days
                        </p>
                        <form class="boxed">
                          {item.tenures.map((obj_sche) =>
                            obj_sche.schedules.map((obj, idex_btn) =>
                              item.amount === obj.properties.principal_amt ? (
                                <div className="boxed_div">
                                  <input
                                    type="radio"
                                    id={obj.properties.product_type_id}
                                    name="skills"
                                    value={obj.properties.product_type_id}
                                    defaultChecked={
                                      obj.properties.tenure_days ===
                                        Math.max.apply(
                                          Math,
                                          item.tenures.map((objq) =>
                                            objq.schedules.map(
                                              (objhy) =>
                                                objhy.properties.tenure_days
                                            )
                                          )
                                        ) && this.state.selectedItem === ""
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.handleChange(
                                        e,
                                        obj.properties.tenure_days,
                                        obj.properties.product_type_id,
                                        obj.properties.principal_amt,
                                        idex_btn
                                      )
                                    }
                                  />
                                  <label for={obj.properties.product_type_id}>
                                    {" "}
                                    {obj.properties.tenure_days}{" "}
                                  </label>
                                </div>
                              ) : (
                                ""
                              )
                            )
                          )}
                        </form>

                        {item.tenures.map((obj_sche) =>
                          obj_sche.schedules.map(
                            (obj_schedule) =>
                              obj_schedule.properties.schedules &&
                              obj_schedule.properties.schedules.map(
                                (obj_schedule_card, index) =>
                                  obj_schedule.properties.tenure_days ===
                                    Math.max.apply(
                                      Math,
                                      item.tenures.map((objq) =>
                                        objq.schedules.map(
                                          (objhy) =>
                                            objhy.properties.tenure_days
                                        )
                                      )
                                    ) && this.state.selectedItem === "" ? (
                                    <div>
                                      <div className="cde_schedule_card">
                                        <div className="product_list_schedule_details_firstdiv">
                                          <h6>
                                            LFI:{obj_schedule_card.schedule_num}
                                          </h6>
                                        </div>

                                        <div className="product_list_schedule_details_seconddiv">
                                          <h1>
                                            ₹
                                            {obj_schedule_card.due_amount.toLocaleString()}
                                          </h1>
                                          <h6>
                                            DUE ON{" "}
                                            {Moment()
                                              .add(
                                                obj_schedule_card.schedule_days -
                                                  1,
                                                "d"
                                              )
                                              .format("DD-MM-yyyy")}
                                          </h6>
                                        </div>

                                        <div className="product_list_schedule_details_thirddiv">
                                          <h6>DUE IN</h6>
                                          <h1>
                                            {obj_schedule_card.schedule_days}
                                          </h1>
                                          <h6>Days</h6>
                                        </div>
                                      </div>
                                      {index ===
                                      obj_schedule.properties.schedules.length -
                                        1 ? (
                                        ""
                                      ) : (
                                        <div className="vertical_hr_schedules"></div>
                                      )}
                                    </div>
                                  ) : obj_schedule.properties.isSelected ===
                                    true ? (
                                    <div>
                                      <div className="cde_schedule_card">
                                        <div className="product_list_schedule_details_firstdiv">
                                          <h6>
                                            LFI:{obj_schedule_card.schedule_num}
                                          </h6>
                                        </div>

                                        <div className="product_list_schedule_details_seconddiv">
                                          <h1>
                                            ₹{obj_schedule_card.due_amount}
                                          </h1>
                                          <h6>
                                            DUE ON{" "}
                                            {Moment()
                                              .add(
                                                obj_schedule_card.schedule_days -
                                                  1,
                                                "d"
                                              )
                                              .format("DD-MM-yyyy")}
                                          </h6>
                                        </div>

                                        <div className="product_list_schedule_details_thirddiv">
                                          <h6>DUE IN</h6>
                                          <h1>
                                            {obj_schedule_card.schedule_days}
                                          </h1>
                                          <h6>Days</h6>
                                        </div>
                                      </div>
                                      {index ===
                                      obj_schedule.properties.schedules.length -
                                        1 ? (
                                        ""
                                      ) : (
                                        <div className="vertical_hr_schedules"></div>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )
                              )
                          )
                        )}

                        {item.tenures.map((tenure) =>
                          tenure.schedules.map((schedule) =>
                            schedule.properties.isSelected === true
                              ? schedule.properties.loan_details.map(
                                  (obj_loan_details) => (
                                    <div className="dynamic_ui">
                                      {obj_loan_details.title ===
                                      "dividerValue" ? (
                                        <hr
                                          style={{
                                            color: obj_loan_details.color,
                                            marginTop: "8px",
                                            marginRight: "10px",
                                          }}
                                        ></hr>
                                      ) : (
                                        <div className="dynamic_ui_flex">
                                          <div className="dynamic_ui_flex_left">
                                            <p style={{ color: "#1c1c1c" }}>
                                              {obj_loan_details.title}
                                              <br />
                                            </p>
                                            <span style={{ color: "#1c1c1c" }}>
                                              {obj_loan_details.subTitle &&
                                                obj_loan_details.subTitle}
                                            </span>
                                          </div>
                                          <div className="dynamic_ui_flex_right">
                                            <p
                                              style={{
                                                color: obj_loan_details.color,
                                                letterSpacing: "1px",
                                              }}
                                            >
                                              {obj_loan_details.toolTipText ? (
                                                <Tooltip
                                                  toolTipText={
                                                    obj_loan_details.toolTipText
                                                  }
                                                />
                                              ) : (
                                                ""
                                              )}
                                              {obj_loan_details.description}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )
                                )
                              : ""
                          )
                        )}

                        {item.tenures.map((tenure) =>
                          tenure.schedules.map((schedule) =>
                            schedule.properties.isSelected === true ? (
                              <div>
                                <a
                                  href="https://play.google.com/store/apps/details?id=in.loanfront.android&hl=en"
                                  target="_blank"
                                >
                                  {" "}
                                  <button className="verifyGetLoanButton">
                                    GET LOAN FROM APP
                                  </button>
                                </a>
                              </div>
                            ) : (
                              ""
                            )
                          )
                        )}
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              ) : (
                ""
              )
            )}

        {this.state.api_res_react &&
          this.state.api_res_react.results &&
          this.state.api_res_react.results.products &&
          this.state.api_res_react.results.context === "product" &&
          this.state.api_res_react.results.products.map((item, i) =>
            item.isLocked === true ? (
              <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                className="bcd"
                onClick={(e) =>
                  this.accordion_function(item.amount, item.tenures)
                }
              >
                <AccordionItem className="abc">
                  <AccordionItemButton className="cde">
                    {item.tenures &&
                      item.tenures.map((obj) =>
                        obj.schedules.map((schedule) =>
                          schedule.properties.isSelected === true ? (
                            <div className="product_list_left">
                              <h1
                                style={{
                                  color:
                                    schedule.properties.tenure_days === 62 ||
                                    schedule.properties.tenure_days === 63
                                      ? "#2aad62"
                                      : schedule.properties.tenure_days === 15
                                      ? "#eebf31"
                                      : "#1d2a78",
                                }}
                              >
                                ₹{item.amount.toLocaleString()}
                              </h1>
                              {item.isOpened === true ? (
                                ""
                              ) : schedule.properties.is_locked === 1 ? (
                                <div className="product_locked_card">
                                  <h6>
                                    {"₹" +
                                      Math.max.apply(
                                        Math,
                                        item.tenures.map((objq) =>
                                          objq.schedules.map(
                                            (objhy) =>
                                              objhy.properties.interest_amount
                                          )
                                        )
                                      ) +
                                      " interest"}
                                  </h6>
                                  <img
                                    src={LockedProduct}
                                    alt="LockedProduct"
                                  />
                                </div>
                              ) : (
                                <h6>
                                  {"₹" +
                                    Math.max.apply(
                                      Math,
                                      item.tenures.map((objq) =>
                                        objq.schedules.map(
                                          (objhy) =>
                                            objhy.properties.interest_amount
                                        )
                                      )
                                    ) +
                                    " interest"}
                                </h6>
                              )}
                            </div>
                          ) : (
                            ""
                          )
                        )
                      )}

                    {item.tenures &&
                      item.tenures.map((obj) =>
                        obj.schedules.map((schedule) =>
                          schedule.properties.isSelected === true ? (
                            <div
                              className="product_list_right"
                              style={{
                                backgroundColor:
                                  schedule.properties.tenure_days === 62 ||
                                  schedule.properties.tenure_days === 63
                                    ? "#2aad62"
                                    : schedule.properties.tenure_days === 15
                                    ? "#eebf31"
                                    : "#1d2a78",
                              }}
                            >
                              <h1
                                style={{
                                  backgroundColor:
                                    schedule.properties.tenure_days === 62 ||
                                    schedule.properties.tenure_days === 63
                                      ? "#2aad62"
                                      : schedule.properties.tenure_days === 15
                                      ? "#eebf31"
                                      : "#1d2a78",
                                }}
                              >
                                {schedule.properties.tenure_days}
                              </h1>
                              <h6
                                style={{
                                  backgroundColor:
                                    schedule.properties.tenure_days === 62 ||
                                    schedule.properties.tenure_days === 63
                                      ? "#2aad62"
                                      : schedule.properties.tenure_days === 15
                                      ? "#eebf31"
                                      : "#1d2a78",
                                }}
                              >
                                Days
                              </h6>
                            </div>
                          ) : (
                            ""
                          )
                        )
                      )}
                  </AccordionItemButton>

                  <AccordionItemPanel
                    className="abdcde"
                    style={{
                      display:
                        item.tenures &&
                        item.tenures.map((obj) =>
                          obj.schedules.map((schedule) =>
                            schedule.properties.is_locked === 1 ? "none" : ""
                          )
                        ),
                    }}
                  >
                    {/* <div className='panel_open'>  */}
                    <div>
                      {item.tenures &&
                        item.tenures.map((obj) =>
                          obj.schedules.map((schedule) =>
                            schedule.properties.isSelected === true ? (
                              <p
                                className="panel_open"
                                style={{
                                  borderTopColor:
                                    schedule.properties.tenure_days === 62 ||
                                    schedule.properties.tenure_days === 63
                                      ? "#2aad62"
                                      : schedule.properties.tenure_days === 15
                                      ? "#eebf31"
                                      : "#1d2a78",
                                }}
                              ></p>
                            ) : (
                              ""
                            )
                          )
                        )}

                      <p className="product_list_choose_nbfc_p">
                        Choose Tenure Days
                      </p>
                      <form class="boxed">
                        {item.tenures.map((obj_sche) =>
                          obj_sche.schedules.map((obj, idex_btn) =>
                            item.amount === obj.properties.principal_amt ? (
                              <div className="boxed_div">
                                <input
                                  type="radio"
                                  id={obj.properties.product_type_id}
                                  name="skills"
                                  value={obj.properties.product_type_id}
                                  defaultChecked={
                                    obj.properties.tenure_days ===
                                      Math.max.apply(
                                        Math,
                                        item.tenures.map((objq) =>
                                          objq.schedules.map(
                                            (objhy) =>
                                              objhy.properties.tenure_days
                                          )
                                        )
                                      ) && this.state.selectedItem === ""
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this.handleChange(
                                      e,
                                      obj.properties.tenure_days,
                                      obj.properties.product_type_id,
                                      obj.properties.principal_amt,
                                      idex_btn
                                    )
                                  }
                                />
                                <label for={obj.properties.product_type_id}>
                                  {" "}
                                  {obj.properties.tenure_days}{" "}
                                </label>
                              </div>
                            ) : (
                              ""
                            )
                          )
                        )}
                      </form>

                      {item.tenures.map((obj_sche) =>
                        obj_sche.schedules.map(
                          (obj_schedule) =>
                            obj_schedule.properties.schedules &&
                            obj_schedule.properties.schedules.map(
                              (obj_schedule_card, index) =>
                                obj_schedule.properties.tenure_days ===
                                  Math.max.apply(
                                    Math,
                                    item.tenures.map((objq) =>
                                      objq.schedules.map(
                                        (objhy) => objhy.properties.tenure_days
                                      )
                                    )
                                  ) && this.state.selectedItem === "" ? (
                                  <div>
                                    <div className="cde_schedule_card">
                                      <div className="product_list_schedule_details_firstdiv">
                                        <h6>
                                          LFI:{obj_schedule_card.schedule_num}
                                        </h6>
                                      </div>

                                      <div className="product_list_schedule_details_seconddiv">
                                        <h1>
                                          ₹
                                          {obj_schedule_card.due_amount.toLocaleString()}
                                        </h1>
                                        <h6>
                                          DUE ON{" "}
                                          {Moment()
                                            .add(
                                              obj_schedule_card.schedule_days -
                                                1,
                                              "d"
                                            )
                                            .format("DD-MM-yyyy")}
                                        </h6>
                                      </div>

                                      <div className="product_list_schedule_details_thirddiv">
                                        <h6>DUE IN</h6>
                                        <h1>
                                          {obj_schedule_card.schedule_days}
                                        </h1>
                                        <h6>Days</h6>
                                      </div>
                                    </div>
                                    {index ===
                                    obj_schedule.properties.schedules.length -
                                      1 ? (
                                      ""
                                    ) : (
                                      <div className="vertical_hr_schedules"></div>
                                    )}
                                  </div>
                                ) : obj_schedule.properties.isSelected ===
                                  true ? (
                                  <div>
                                    <div className="cde_schedule_card">
                                      <div className="product_list_schedule_details_firstdiv">
                                        <h6>
                                          LFI:{obj_schedule_card.schedule_num}
                                        </h6>
                                      </div>

                                      <div className="product_list_schedule_details_seconddiv">
                                        <h1>₹{obj_schedule_card.due_amount}</h1>
                                        <h6>
                                          DUE ON{" "}
                                          {Moment()
                                            .add(
                                              obj_schedule_card.schedule_days -
                                                1,
                                              "d"
                                            )
                                            .format("DD-MM-yyyy")}
                                        </h6>
                                      </div>

                                      <div className="product_list_schedule_details_thirddiv">
                                        <h6>DUE IN</h6>
                                        <h1>
                                          {obj_schedule_card.schedule_days}
                                        </h1>
                                        <h6>Days</h6>
                                      </div>
                                    </div>
                                    {index ===
                                    obj_schedule.properties.schedules.length -
                                      1 ? (
                                      ""
                                    ) : (
                                      <div className="vertical_hr_schedules"></div>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )
                            )
                        )
                      )}

                      {item.tenures.map((tenure) =>
                        tenure.schedules.map((schedule) =>
                          schedule.properties.isSelected === true
                            ? schedule.properties.loan_details.map(
                                (obj_loan_details) => (
                                  <div className="dynamic_ui">
                                    {obj_loan_details.title ===
                                    "dividerValue" ? (
                                      <hr
                                        style={{
                                          color: obj_loan_details.color,
                                          marginTop: "8px",
                                          marginRight: "10px",
                                        }}
                                      ></hr>
                                    ) : (
                                      <div className="dynamic_ui_flex">
                                        <div className="dynamic_ui_flex_left">
                                          <p style={{ color: "#1c1c1c" }}>
                                            {obj_loan_details.title}
                                            <br />
                                          </p>
                                          <span style={{ color: "#1c1c1c" }}>
                                            {obj_loan_details.subTitle &&
                                              obj_loan_details.subTitle}
                                          </span>
                                        </div>
                                        <div className="dynamic_ui_flex_right">
                                          <p
                                            style={{
                                              color: obj_loan_details.color,
                                              letterSpacing: "1px",
                                            }}
                                          >
                                            {obj_loan_details.toolTipText ? (
                                              <Tooltip
                                                toolTipText={
                                                  obj_loan_details.toolTipText
                                                }
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {obj_loan_details.description}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              )
                            : ""
                        )
                      )}

                      {item.tenures.map((tenure) =>
                        tenure.schedules.map((schedule) =>
                          schedule.properties.isSelected === true ? (
                            <div>
                              <a
                                href="https://play.google.com/store/apps/details?id=in.loanfront.android&hl=en"
                                target="_blank"
                              >
                                {" "}
                                <button className="verifyGetLoanButton">
                                  GET LOAN FROM APP
                                </button>
                              </a>
                            </div>
                          ) : (
                            ""
                          )
                        )
                      )}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            ) : (
              ""
            )
          )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    product_list_result:
      state.homePageApiProducts &&
      state.homePageApiProducts.data &&
      state.homePageApiProducts,
    productList:
      state.homePageApiProducts &&
      state.homePageApiProducts.results &&
      state.homePageApiProducts.results.products,
  };
}

const mapDispatchToProps = {
  homePageApiProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsListHome);
