import axios from "axios";
import { baseURL } from "../utils/ApiRequestPaths";
import { tokenConfig } from "../utils/Configuration";
import {
  FETCH_ACC_DETAILS_REQUEST,
  FETCH_ACC_DETAILS_SUCCESS,
  FETCH_ACC_DETAILS_FAIL,
  CLEAR_ACCOUNT_DETAILS,
} from "../utils/Types";

export const AccountDetailsData = () => (dispatch, getState) => {
  dispatch({ type: FETCH_ACC_DETAILS_REQUEST });
  axios
    .get(`${baseURL}/user/getBanks`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FETCH_ACC_DETAILS_SUCCESS,
        payload: res,
      });
      return;
    })
    .catch((err) => {
      dispatch({
        type: FETCH_ACC_DETAILS_FAIL,
        payload: err.response,
      });
    });
};

export const clearAccountDetails = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_ACCOUNT_DETAILS });
};
