export const REPAYMENT_OPTIONS_START = "REPAYMENT_OPTIONS_START";
export const REPAYMENT_OPTIONS_SUCCESS = "REPAYMENT_OPTIONS_SUCCESS";
export const REPAYMENT_OPTIONS_FAIL = "REPAYMENT_OPTIONS_FAIL";

export const REPAYMENT_INITIATE_START = "REPAYMENT_INITIATE_START";
export const REPAYMENT_INITIATE_SUCCESS = "REPAYMENT_INITIATE_SUCCESS";
export const REPAYMENT_INITIATE_FAIL = "REPAYMENT_INITIATE_FAIL";

export const REPAYMENT_HOME_START = "REPAYMENT_HOME_START";
export const REPAYMENT_HOME_SUCCESS = "REPAYMENT_HOME_SUCCESS";
export const REPAYMENT_HOME_FAIL = "REPAYMENT_HOME_FAIL";

export const POST_APPLY_COUPON_REQ_START = "POST_APPLY_COUPON_REQ_START";
export const POST_APPLY_COUPON_REQ_SUCCESS = "POST_APPLY_COUPON_REQ_SUCCESS";
export const POST_APPLY_COUPON_REQ_FAILED = "POST_APPLY_COUPON_REQ_FAILED";

export const FETCH_COUPEN_DATA_REQUEST = "FETCH_COUPEN_DATA_REQUEST";
export const FETCH_COUPEN_DATA_SUCCESS = "FETCH_COUPEN_DATA_SUCCESS";
export const FETCH_COUPEN_DATA_FAIL = "FETCH_COUPEN_DATA_FAIL";

export const FETCH_PROFILE_DETAILS_REQUEST = "FETCH_PROFILE_DETAILS_REQUEST";
export const FETCH_PROFILE_DETAILS_SUCCESS = "FETCH_PROFILE_DETAILS_SUCCESS";
export const FETCH_PROFILE_DETAILS_FAIL = "FETCH_PROFILE_DETAILS_FAIL";

export const FETCH_LOAN_HISTORY_REQUEST = "FETCH_LOAN_HISTORY_REQUEST";
export const FETCH_LOAN_HISTORY_SUCCESS = "FETCH_LOAN_HISTORY_SUCCESS";
export const FETCH_LOAN_HISTORY_FAIL = "FETCH_LOAN_HISTORY_FAIL";
export const CLEAR_LOAN_HISTORY = "CLEAR_LOAN_HISTORY";

export const FETCH_ACC_DETAILS_REQUEST = "FETCH_ACC_DETAILS_REQUEST";
export const FETCH_ACC_DETAILS_SUCCESS = "FETCH_ACC_DETAILS_SUCCESS";
export const FETCH_ACC_DETAILS_FAIL = "FETCH_ACC_DETAILS_FAIL";
export const CLEAR_ACCOUNT_DETAILS = "CLEAR_ACCOUNT_DETAILS";

export const FETCH_PROFIE_PIC_REQUEST = "FETCH_PROFIE_PIC_REQUEST";
export const FETCH_PROFIE_PIC_SUCCESS = "FETCH_PROFIE_PIC_SUCCESS";
export const FETCH_PROFIE_PIC_FAIL = "FETCH_PROFIE_PIC_FAIL";
export const CLEAR_PROFILE_DETAILS = "CLEAR_PROFILE_DETAILS";

export const HOME_PAGE_API_PRODUCTS_LIST_START =
  "HOME_PAGE_API_PRODUCTS_LIST_START";
export const HOME_PAGE_API_PRODUCTS_LIST_SUCCESS =
  "HOME_PAGE_API_PRODUCTS_LIST_SUCCESS";
export const HOME_PAGE_API_PRODUCTS_LIST_FAIL =
  "HOME_PAGE_API_PRODUCTS_LIST_FAIL";
