import {
  HOME_PAGE_API_PRODUCTS_LIST_START,
  HOME_PAGE_API_PRODUCTS_LIST_SUCCESS,
  HOME_PAGE_API_PRODUCTS_LIST_FAIL,
} from "../utils/Types";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case HOME_PAGE_API_PRODUCTS_LIST_START:
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };

    case HOME_PAGE_API_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };

    case HOME_PAGE_API_PRODUCTS_LIST_FAIL:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
