export const Strings = {
  dueDate:
    "You can either choose to pay a small amount now and extend your due date, or convert your loan to EMI.",
  extendDueDate: "Extend Due Date",
  deferRepayment: "Defer Repayment",
  currentDueDate: "Current Due Date",
  revisedDueDate: "Revised Due Date",
  revisedInterest: "Revised Interest",
  convertToEMI: "Convert to EMI",
  recomenddEMI:
    "Choose how much you want to pay for your first EMI, we recommend 50-50",
  agreeTerms: "I agree to the Terms & Conditions",
  payAndExit: "pay & extend due date",
  moratorium:
    "At a time when our nation is facing unprecedented disruption due to COVID-19, and as per advice of the Reserve Bank of India we extend our support by moratorium to our eligible customers’ loans who might be facing cash flow issues.",
  moratoriumTermsConditions: "Moratorium Terms & Conditions",
  benefit1: "benefit 1",
  benefit2: "benefit 2",
  benefitsHeading: "Benefits of Extending Due Date",
  convenienceCharge: "*Service provider may charge a convenience fee",
  choosePayment: "Choose Payment Gateway to proceed",
  loanDeferFee: "Loan Defer Fee",
  numberOfEMI: "No. Of EMI’s",
  lfi1: "lfi : 1",
  dueIn: " due in ",
  overDue: "OVERDUE",
  dueOn: "<uppercase> due on </uppercase>",
  dueOnSince: "due since",
  paidOn: "<uppercase> paid on </uppercase>",
  dueToday: "due today",
  days: "days",
  gst: "gst",
  emi: "emi",
  getReport: "Get Report",
  activeLoan: "Active Loan ID",
  details: "Details",
  creditScore: "Your credit score is",
  creditReport: "Your Credit Reports",
  completeReport: "Now you can get a Complete credit report here",
  foreFront: "at the forefront <p>of your</p> <p>financial needs</p>",
  helpTrue: "?help=true",
  good: "good",
  Loandate: "Loan date  ",
  BnakAcc: "Bank Acc   ",
  enterWithoutSpace: "Enter coupon code without space",
  noCouponMessage: "You don't have any coupon available now ",
  isOverDue: 1,
  payNowString: "PAY ₹{0} NOW",
  nowString: " Now ",
  dollarSymbol: "₹",
  repaymentAmount: "Repayment Amount ",
  mycoupens: "My Coupons",

  lfi: "LFI : ",
  dueSince: "due since",
  plus: "+",
  overDueBy: "overdue by",

  //PgSuccess
  gotoPgOnSuccess: "Go to PG, on Success",
  backToAndroid: "come Back to Android",
  //EmiSuccess

  congrats: "Congratulations",
  loanConverted: "Your loan has been converted to",
  emiLoan: "EMI Loan",
  loanDetails: "view loan details",

  applycoupon: "Apply Coupon",
  pleaseEnterCouponCode: "Please Enter Coupon code",
  couponLength: "Coupon code length should be between {0} to {1} characters",
  couponapplied: "Coupon Applied",
  apply: "apply",
  off: "off",
  expired: "expired",
  locked: "locked",
  usecouponbtn: "use coupon",

  paymentSuccess: "payment success",
  thankyouText: "Thank you! We have received your payment of ₹{0}",
  getnewloan: "<upper>get a new loan</upper>",
  hello: "Hello",
  paymentStatus: "upcoming payment",
  overdue: "<uppercase> overdue <uppercase/>",
  findcoupen: "Find your available coupons below",

  //repay-success
  loanRepayment: "Loan Repayment",
  repayNow: "PAY {0} NOW",
  offervalidtill: "Offer valid till {0}",
  inclusiveLatePaymentFee: "Inclusive of 18% GST",
};
