import React, { useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../redux/utils/ApiRequestPaths";
import { tokenConfig } from "../../redux/utils/Configuration";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PhonePe(props) {
  useEffect(() => {
    const data = {
      properties: {
        loan_schedule_id: sessionStorage.getItem("loanScheduleId"),
        loan_id: sessionStorage.getItem("loanId"),
        repayment_transaction_id: sessionStorage.getItem("repayTransId"),
        merchantTranId: sessionStorage.getItem("merchantTransId"),
        txnId: null,
        responseCode: null,
        approvalRefNo: null,
        txnRef: null,
        status: null,
      },
    };

    axios
      .post(`${baseURL}/repayments/phonepe/checkstatus?`, data, tokenConfig())
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === "OK") {
            if (res.data.message === "repayment_transaction_pending") {
              toast.warn("Payment is pending", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (res.data.message === "repayment_transaction_success") {
              if (sessionStorage.getItem("schedulesPending") === "1") {
                sessionStorage.setItem("phonepeFinalSuccess", true);
              } else {
                sessionStorage.setItem("phonepeSuccess", true);
              }
            }
            if (res.data.message === "repayment_transaction_fail") {
              sessionStorage.setItem("phonepeFail", true);
            }
            window.location.replace("/");
          }
          if (res.data.status === "UNAUTHORIZED") {
            window.location.replace("/login");
          }
        }
      })
      .catch((err) => {
        sessionStorage.setItem("phonepeFail", true);
        window.location.replace("/");
      });
  }, []);
  return <div></div>;
}

export default PhonePe;
