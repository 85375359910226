import React, { useState, useEffect } from "react";
import "./UserDetails.scss";
import ProfileDetails from "./ProfileDetails";
import LoanHistory from "./LoanHistory";
import AccountDetails from "./AccountDetails";
import { connect } from "react-redux";
import { repaymentHome } from "../../redux/actions/RepaymentHome";

const UserDetails = (props) => {
  const [display, setDisplay] = useState({
    isProfile: true,
    isLoanhistory: false,
    isAccountDetails: false,
  });
  const [buttonborder, setButtonborder] = useState({
    profileBtn: true,
    loanBtn: false,
    accountBtn: false,
  });

  const showLoanHistory = () => {
    setDisplay({
      isProfile: false,
      isLoanhistory: true,
      isAccountDetails: false,
    });
    setButtonborder({
      profileBtn: false,
      loanBtn: true,
      accountBtn: false,
    });
  };
  const showProfile = () => {
    setDisplay({
      isProfile: true,
      isLoanhistory: false,
      isAccountDetails: false,
    });
    setButtonborder({
      loanBtn: false,
      profileBtn: true,
      accountBtn: false,
    });
  };

  const showAccountDetails = () => {
    setDisplay({
      isProfile: false,
      isLoanhistory: false,
      isAccountDetails: true,
    });
    setButtonborder({
      loanBtn: false,
      profileBtn: false,
      accountBtn: true,
    });
  };

  useEffect(() => {
    props.repaymentHome();
  }, []);

  return (
    <>
      <div className="container-fluid mt-2" style={{ background: "#f7f7f7" }}>
        <div className="row">
          <div className="col-md-4 leftContiner">
            <div className="profileImage">
              <img src={props && props.profile} />
            </div>
            <div className="profileOptions">
              <ul>
                <li onClick={showProfile}>
                  <button
                    className={
                      buttonborder.profileBtn ? "borderBtn" : "userbtn"
                    }
                  >
                    PROFILE DETAILS
                  </button>
                </li>
                <li onClick={showAccountDetails}>
                  <button
                    className={
                      buttonborder.accountBtn ? "borderBtn" : "userbtn"
                    }
                  >
                    ACCOUNT DETAILS
                  </button>
                </li>
                <li onClick={showLoanHistory}>
                  <button
                    className={buttonborder.loanBtn ? "borderBtn" : "userbtn"}
                  >
                    LOAN HISTORY
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            <div>
              {display.isProfile && (
                <ProfileDetails ChangeActiveComp={props.ChangeActiveComp} />
              )}
              {display.isLoanhistory && (
                <LoanHistory ChangeActiveComp={props.ChangeActiveComp} />
              )}
              {display.isAccountDetails && (
                <AccountDetails ChangeActiveComp={props.ChangeActiveComp} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapState = (state) => {
  return {
    profile:
      state.RepaymentHome &&
      state.RepaymentHome.data &&
      state.RepaymentHome.data.results &&
      state.RepaymentHome.data.results.selfieEntity,
  };
};
const mapDispatch = {
  repaymentHome,
};

export default connect(mapState, mapDispatch)(UserDetails);
