import React from "react";
import "./App.scss";
import Container from "./Screens/Container/Container";
import { store } from "./redux/store/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import phonePe from "./Screens/upiPay/phonePe";
import Login from "./Screens/Login/Login";
import AccountDeletion from "./Screens/AccountDeletion/AccountDeletion";
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" component={Container} />
            <Route exact path="/accountDeletion" component={AccountDeletion} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/phonePe" component={phonePe} />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
