import React from "react";
import "./Home.scss";
import vvplLogo from "../../assets/img/vvplLogo.svg";
import LoanfrontLogo from "../../assets/img/LoanfrontLogo.svg";
import playstoreLogo from "../../assets/img/playstoreLogo.png";
import loanfrontMobileLogo from "../../assets/img/loanfrontMobileLogo.png";
import styleIcon from "../../assets/img/styleIcon.svg";
import UserDetails from "../UserDetails/UserDetails";
import { store } from "../../redux/store/store";
import { Provider } from "react-redux";

function Home(props) {
  return (
    <div className="homeMain">
      <div style={{ width: "100%" }}>
        <div className="homelogoDiv">
          <div>
            <img src={LoanfrontLogo} />
          </div>
          <div>
            <img src={vvplLogo} />
          </div>
        </div>
        {props.login.activeLoan ||
        props.login.success ||
        props.login.download ||
        props.login.paymentScreen ||
        props.login.upiPay ||
        props.login.finalSuccess ? (
          <UserDetails ChangeActiveComp={props.ChangeActiveComp} />
        ) : (
          <div className="homeContent">
            <div>
              <img src={loanfrontMobileLogo} />
            </div>
            <div className="homeTextContent">
              <div>
                <h1>
                  We thank you for <br />
                  being a <span>valued</span> and <br /> <span>trusted</span>{" "}
                  customer
                </h1>
              </div>
            </div>
          </div>
        )}
        {props.login.activeLoan ||
        props.login.success ||
        props.login.download ||
        props.login.paymentScreen ||
        props.login.upiPay ||
        props.login.finalSuccess ? (
          ""
        ) : (
          <div className="styleIconCss">
            <img src={styleIcon} />
          </div>
        )}
      </div>
      {/* <footer className="footerStyle">
        <span>Download the app</span>
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=in.loanfront.android&hl=en"
        >
          <img src={playstoreLogo} />
        </a>
      </footer> */}
    </div>
  );
}

export default Home;
