import React, { useState } from "react";
import DeleteLogin from "./DeleteLogin";
import { baseURL } from "../../redux/utils/ApiRequestPaths";
import axios from "axios";
import Home from "../Container/Home";
import "./AccountDeletion.scss";
import vvplLogo from "../../assets/img/vvplLogo.svg";
import LoanfrontLogo from "../../assets/img/LoanfrontLogo.svg";
import styleIconLeft from "../../assets/img/styleIconLeft.svg";
import styleIconRight from "../../assets/img/styleIconRight.svg";
import ConfirmDeleteAccount from "./ConfirmDeleteAccount";
import OtpConfirm from "./OtpConfirm";
import DeleteSuccess from "./DeleteSuccess";

function AccountDeletion(props) {
  const [activeComp, setActiveComp] = useState({
    userAuth: true,
    userConfirmDeletion: false,
    userOtpConfirm: false,
    successDelete: false,
  });

  const [activepage, setActivepage] = useState({
    home: true,
    downloadApp: false,
  });

  const ChangeActiveComp = (target, checkComp) => {
    const oldState = { ...activeComp };
    Object.keys(oldState).map((el) => {
      if (el === target) {
        return (oldState[el] = true);
      } else {
        return (oldState[el] = false);
      }
    });
    setActiveComp(oldState);
    if (target === "userAuth" && checkComp !== "OtpConfirm") {
      const savehost = sessionStorage.getItem("host");
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem("host", savehost);
    }
    return;
  };

  const callResendOTPForDelete = async () => {
    const number = sessionStorage.getItem("mobile_number");
    if (number) {
      const data = {
        properties: {
          phone_number: number,
        },
      };
      await axios
        .post(`${baseURL}/lf/delete_account?type=send_otp`, data)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              if (res.data.status) {
                if (res.data.status === "OK") {
                  ChangeActiveComp("userOtpConfirm", "");
                }
              }
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data) {
              if (err.response.data.message) {
                return err.response.data.message;
              } else {
                return "Please login with the registered mobile number";
              }
            }
          }
        });
    } else {
      return false;
    }
  };

  return (
    <div className="contmainDiv">
      {activepage.home && (
        <>
          <div className="left">
            <Home login={activeComp} ChangeActiveComp={ChangeActiveComp} />
          </div>
          <div className="right">
            {activeComp.userAuth && (
              <DeleteLogin ChangeActiveComp={ChangeActiveComp} />
            )}
            {activeComp.userConfirmDeletion && (
              <ConfirmDeleteAccount ChangeActiveComp={ChangeActiveComp} />
            )}
            {activeComp.userOtpConfirm && (
              <OtpConfirm
                ChangeActiveComp={ChangeActiveComp}
                callResendOTPForDelete={callResendOTPForDelete}
              />
            )}
            {activeComp.successDelete && (
              <DeleteSuccess ChangeActiveComp={ChangeActiveComp} />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default AccountDeletion;
