import {
   MOBILE_NUMBER,
   TOKEN,
   REFRESH_TOKEN,
   USER_NAME,
   USER_ID,
   USER_STATUS,
   LATITUDE,
   LONGITUDE,
   USER_SELFIE,
   VERSION_NAME,
   BUILD_NUMBER
} from "../utils/Constants";


export const SaveMobileNumber = (mobile_num) => {
    localStorage.setItem(MOBILE_NUMBER,mobile_num)
};

export const getSavedMobileNumber = () => {
   return localStorage.getItem(MOBILE_NUMBER)
}

export const saveToken = (token) => {
    localStorage.setItem(TOKEN,token)
};

export const getSavedToken = () => {
   return localStorage.getItem(TOKEN)
}

export const saveRefreshToken = (refreshToken) => {
    localStorage.setItem(REFRESH_TOKEN,refreshToken)
};

export const getSavedRefreshToken = () => {
   return localStorage.getItem(REFRESH_TOKEN)
}

export const saveUserName = (userName) => {
    localStorage.setItem(USER_NAME,userName)
};

export const getSavedUserName = () => {
   return localStorage.getItem(USER_NAME)
}

export const saveUserId = (userId) => {
    localStorage.setItem(USER_ID,userId)
};

export const getSavedUserId = () => {
   return localStorage.getItem(USER_ID)
}

export const saveUserStatus = (userStatus) => {
    localStorage.setItem(USER_STATUS,userStatus)
};

export const getSavedUserStatus = () => {
   return localStorage.getItem(USER_STATUS)
}

export const getSavedLatitude = () => {
    return localStorage.getItem(LATITUDE)
 }

 export const getSavedLongitude = () => {
    return localStorage.getItem(LONGITUDE)
 }

 export const saveSelfie = (selfie) => {
   localStorage.setItem(USER_SELFIE,selfie)
};

export const getSavedSelfie = () => {
  return localStorage.getItem(USER_SELFIE)
}

export const getVersionName = () => {
   return localStorage.getItem(VERSION_NAME)
 }

 export const getBuildNumber = () => {
   return localStorage.getItem(BUILD_NUMBER)
 }