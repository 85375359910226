import React, { useState, useRef, useEffect } from "react";
import Logo from "../../assets/img/LoanfrontColLogo.svg";
import "./Login.scss";
import { baseURL } from "../../redux/utils/ApiRequestPaths";
import axios from "axios";
import CustomLoader from "../../utils/customLoader";
import vaibhavLogo from "../../assets/img/vaibhavLogo.svg";
import VvplLogo from "../../assets/img/vvpl.svg";
import facebook from "../../assets/img/facebook.svg";
import instagram from "../../assets/img/instagram.svg";
import linkedin from "../../assets/img/linkedin.svg";
import twitter from "../../assets/img/twitter.svg";
import youtube from "../../assets/img/youtube.svg";

const getTarget = () => {
  const hostName = window.location.hostname;
  let target = "";
  if (hostName) {
    const list = hostName.split(".");
    list.map((el) => {
      if (el === "vaibhav-vyapaar" || el === "localhost") {
        sessionStorage.setItem("host", "vaibhav-vyapaar");
        document.getElementsByTagName("title")[0].innerHTML =
          "Vaibhav-vyapaar_repayment";
        document.getElementById("favicon").href = VvplLogo;
        target = "vaibhav-vyapaar";
      }
    });
  }
  return target;
};

function Login(props) {
  const { ChangeActiveComp } = props;
  const [mobileNumber, setMobileNumber] = useState(
    sessionStorage.getItem("mobile_number")
      ? sessionStorage.getItem("mobile_number")
      : ""
  );
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [mobileStartError, setMobileStartError] = useState(false);
  const [notRegError, setNotRegError] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [host, setHost] = useState(getTarget());
  const numRef = useRef();

  const handleKeyPress = (event) => {
    if (event.which === 13) {
      CallLoginApi();
    }
  };

  const onChangeHandler = (event) => {
    const { value } = event.target;

    if (value !== "") {
      notRegError && setNotRegError(false);
      if (value[0] > 5) {
        let regex = /^[0-9]*$/;
        if (regex.test(value)) {
          setMobileNumber(value);
          mobileNumberError && setMobileNumberError(false);
          mobileStartError && setMobileStartError(false);
        } else {
          setMobileNumberError(true);
        }
      } else {
        setMobileNumberError(false);
        setMobileStartError(true);
        setNotRegError(false);
      }
    } else {
      setMobileNumber(value);
      mobileNumberError && setMobileNumberError(false);
      mobileStartError && setMobileStartError(false);
      notRegError && setNotRegError(false);
    }
  };

  const CallLoginApi = () => {
    if (mobileNumber) {
      if (!mobileNumberError && !mobileStartError && !notRegError) {
        if (mobileNumber.length === 10) {
          const data = {
            properties: {
              phone_number: mobileNumber,
              scope: "customer",
            },
          };
          setLoading(true);
          axios
            .post(`${baseURL}/phone/login?v=3006`, data)
            .then((res) => {
              setLoading(false);
              if (res.status === 200) {
                if (res.data) {
                  if (res.data.status) {
                    sessionStorage.setItem(
                      "mobile_token",
                      res.data.results && res.data.results.mobile_token
                    );
                    sessionStorage.setItem("mobile_number", mobileNumber);
                    return res.data.status === "OK"
                      ? ChangeActiveComp("verifyOtp", "")
                      : setNotRegError(true);
                  }
                }
              }
            })
            .catch((err) => {
              setLoading(false);
              if (err.response) {
                if (err.response.data) {
                  if (err.response.data.message) {
                    setApiMessage(err.response.data.message);
                    setNotRegError(true);
                  } else {
                    setApiMessage(
                      "Please login with the registered mobile number"
                    );
                    setNotRegError(true);
                  }
                }
              }
            });
        } else {
          setMobileNumberError(true);
        }
      }
    } else {
      setMobileNumberError(true);
      mobileNumberError && setMobileNumberError(false);
      mobileStartError && setMobileStartError(false);
      notRegError && setNotRegError(false);
    }
  };

  useEffect(() => {
    numRef.current.focus();
  }, []);

  return (
    <div
      className="loginMainDiv heightDiv mobileViewHeight"
      style={{ backgroundColor: "#1137B2", color: "white" }}
    >
      <div className="loginSubDiv">
        <div className="logoDiv">
          <p className="hello mt-1 mb-0">Hello!</p>
          <p className="getStarted mt-0">Greetings from LoanFront</p>
          <p className="mobileText">
            Login/Signup with your
            <span className="forMobileView"> mobile number</span>
          </p>
        </div>
        <div className="contentDiv">
          <input
            className="customInput"
            type="text"
            placeholder="Registered Mobile Number"
            maxLength="10"
            onChange={onChangeHandler}
            name="mobile number"
            value={mobileNumber}
            ref={numRef}
            onKeyPress={handleKeyPress}
          />
          {mobileNumberError && (
            <span className="errorMessage">
              Please enter a valid mobile number
            </span>
          )}
          {mobileStartError && (
            <span className="errorMessage">
              Mobile number should start with greater then 6
            </span>
          )}
          {notRegError && <span className="errorMessage">{apiMessage}</span>}
          {loading ? (
            <CustomLoader className={"customBtnVvpl"} />
          ) : (
            <button className={"customBtnVvpl"} onClick={CallLoginApi}>
              Log In
            </button>
          )}
        </div>
      </div>
      <div className="footer px-3">
        <div>Let's connect!</div>
        <div className="socialLinks d-flex justify-content-center">
          <a
            className="facebooktag"
            target="_balnk"
            href="https://www.facebook.com/goloanfront/"
          >
            <img className="facebook" src={facebook} />
          </a>
          <a
            className="instaTag"
            target="_balnk"
            href="https://www.instagram.com/go.loanfront/"
          >
            <img className="insta" src={instagram} />
          </a>
          <a
            className="linkedinTag"
            target="_balnk"
            href="https://www.linkedin.com/company/loanfront"
          >
            <img className="linkedin" src={linkedin} />
          </a>
          <a
            className="twitterTag"
            target="_balnk"
            href="https://twitter.com/GoLoanFront"
          >
            <img className="twitter" src={twitter} />
          </a>
          <a
            className="youtubeTag"
            target="_balnk"
            href="https://www.youtube.com/channel/UCg_fW5YeISfhaAVWWpMBOkA"
          >
            <img className="youtubefooter" src={youtube} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
