import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./HomepageProductsList/WebProductsListHome.css";
import ProductsListHome from "../../Screens/ProductScreen/HomepageProductsList/ProductsListHome";
import * as api from "../../redux/actions/homePageApiProducts";
import "../../assets/scss/Web-background-with-card.scss";
import "../../assets/scss/web-homePage.scss";
// import { DefaultLoading } from "@capfront-technologies/loanfront-ui-components";
import { DefaultLoading } from "../Container/Loader/LoaderScreen";

import NoActiveHomeLoan from "../activeHomeLoan/NoActiveHomeLoan";

function HomePage(props) {
  const [homeapires, setHomeapires] = React.useState("");
  const [verify_products, setverify_products] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      callHomeAPI();
    });
  }, []);

  const callHomeAPI = () => {
    api.home_api_products().then(function (res) {
      setverify_products(false);
      if (res && res.results && res.results.context === "product") {
        if (
          res.results.configVersion != null ||
          res.results.configVersion != undefined
        ) {
          if (
            res.results.products != null ||
            res.results.products != undefined
          ) {
            setHomeapires(res);
          }
        }
      }
    });
  };

  return verify_products ? (
    <DefaultLoading />
  ) : (
    <div>
      <div>
        {homeapires.status === "OK" &&
        homeapires.results &&
        homeapires.results.user_status === "loan_eligibility_success" ? (
          <ProductsListHome apires={homeapires} />
        ) : (
          <NoActiveHomeLoan />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
