import React, { useEffect } from "react";
import Verified from "../../assets/img/verified.svg";
import "./Success.scss";

function LoanClosed(props) {
  const { ChangeActiveComp } = props;

  const navigate = () => {
    ChangeActiveComp("activeLoan");
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("phonepeFinalSuccess");
      sessionStorage.removeItem("phonepeSuccess");
      sessionStorage.removeItem("phonepeFail");
    };
  }, []);

  return (
    <div className="loginMainDiv">
      <div className="loginSubDiv">
        <p className="welcomeTxt">Thank you!</p>
        <p className="nameText">
          {sessionStorage.getItem("panName") &&
            sessionStorage.getItem("panName")}
        </p>
        <img className="successImg" src={Verified} alt="Verified" />
        <p className="successMsg">Loan Re-payment success</p>
        <p>
          <span className="successFontColor">
            we have received payments of all the EMIs for loan ID -
          </span>
          <span className="successFont">
            {` ${
              sessionStorage.getItem("loanId") &&
              sessionStorage.getItem("loanId")
            }`}
          </span>
        </p>
        <p className="successFont">Loan closed successfully</p>
        <button className="customBtn" onClick={navigate}>
          OK
        </button>
      </div>
    </div>
  );
}

export default LoanClosed;
