import React from "react";
import ReactTooltip from "react-tooltip";
import ProductTooltip from "../../assets/img/product_tooltip.svg";
import "./tooltip.scss";

class Tooltip extends React.Component {
  state = {
    tooltipdisplay: false,
  };
  render() {
    return (
      <>
        <p
          ref={(ref) => (this.fooRef = ref)}
          data-place="bottom"
          data-event="click"
          data-event-off="click"
          data-background-color="#EEBF31"
          data-tip={this.props.toolTipText}
        ></p>
        <img
          src={ProductTooltip}
          alt="ProductTooltip"
          onClick={() => {
            ReactTooltip.show(this.fooRef);
          }}
        />
        <ReactTooltip />
      </>
    );
  }
}

export default Tooltip;
