// BoosterWaiting Screen

export const UNAUTHORIZED = "UNAUTHORIZED";
export const VERIFIED = "VERIFIED";
export const PRODUCT_HOME_PAGE = "product_home_page";
export const PATH_BOOSTER_WAITING_SCREEN = "/booster-waiting-screen/";
export const CHOOSE_BOOSTER_OPTIONS = "choose_booster_options";
export const LOGIN = "/login";
export const URL_BOOSTER_SCCCESS_POPUP = "?boostersuccesspopup=true";
export const NAVIGATE_PRODUCT_HOME_PAGE = "product_home_page";
export const STATUS_REJECTED = "REJECTED";
export const PAY_SLIP_BANK_STATEMENT_VALIDATION =
  "Pay Slip/Bank Statement Validation";
export const PAY_SLIP_BANK_STATEMENT = "Pay Slips/Bank Statement";
export const NET_BANKING_VALIDATION = "NetBanking Validation";
export const NET_BANKING = "NetBanking";
export const EMPLOYMENT_VALIDATION = "Employment Validation";
export const EMPLOYMENT = "Employment";
export const NO = "no";
export const PAN_NAME = "pan_name";
export const PAN_DETAILS = "pan_details";
export const PERMANENT_PINCODE = "permanent_pincode";
export const CURRENT_PINCODE = "current_pincode";
export const AUTO = "auto";
export const DATA = "data";
export const ON_HOLD = "on_hold";
export const LATITUDE = "latitude";
export const LONGITUDE = "longitude";
export const NOT_SERVING = "not serving in your area";
export const LOAN_ELIGIBILITY_SUCCESS = "loan_eligibility_success";
export const LOAN_REPAYMENT = "Loan Repayment";
export const LOAN_ID = "1234567890";
export const LFI = "1st Instalment";
export const LFI_AMOUNT = "₹4500";
export const PENALITY_CHARGES_TEXT = "Penalty Charges";
export const PENALITY_CHARGES = "₹100";
export const TOTAL_DISCOUNT_TEXT = "Total Discount";
export const TOTAL_DISCOUNT = "-₹100";
export const REPAYMENT_AMOUNT_TEXT = "Repayment Amount";
export const REPAYMENT_AMOUNT = "₹4,175*";
export const COUPEN_APPLIED_TEXT = "Coupen applied";
export const TOTAL_COUPEN_SAVE = "saved a total of ₹100";
export const COUPEN_SAVED_TEXT = "  You have";
export const COUPEN_END_TEXT = "  with the coupen";
export const CHOOSE_PAYMENT_GATEWAY = "Choose Payment Gateway to proceed";
export const CONVENIECE_CHARGE_TEXT =
  "*Service provider may charge a convenience fee";
export const BUTTON_TEXT = "PAY ₹4,075 NOW";
export const DONT_HAVE_ENOUGH_MONEY = "Don’t have enough money?";
export const DONT_HAVE_ENOUGH_MONEY_DESCRIPTION =
  "You can pay only a small amount now to keep your loan active and avoid Impact on your CIBIL Score";
export const DEFER_BUTTON = "DEFER REPAYMENT";
export const MOBIKWIK = "Mobikwik";
export const RAZOR_PAY = "RazorPay";
export const GOOGLE_PAY = "Google Pay";

export const ACTIVE_LOAN_ID_TEXT = "Active Loan ID";
export const ACTIVE_LOAN_ID = "1123354";
export const DEATAILS = "Details";
export const LFI_TEXT = "LFI:1";
export const DUE_ON = "DUE ON 20-05-2021";
export const DUE_IN_TEXT = "DUE IN";
export const DUE_IN_DATE = "31";
export const DUE_IN_DAYS_TEXT = "Days";
export const DUE_AMOUNT = "₹3,587";
export const EMI_SUCCESS_PAY_NOW_BTN = "PAY ₹4,075 NOW";
export const SUCCESSFULLY_REPAID_TEXT = "Successfully repaid your loan";
export const TAHNKYOU_TEXT = " Thank you! We have received your payment of";
export const TAHNKYOU_TEXT_END = "₹8085";
export const SHAREDETAILS_TEXT = "share details";
export const GET_A_NEW_LOAN = "GET A NEW LOAN";

//const used for deferment screens
export const DEFER_REPAYMENT = "Defer Repayment";
export const EXTEND_DUE_DATE = "Extend Due Date";
export const CURRENT_DUE_DATE = "Current Due Date";
export const REVISED_DUE_DATE = "Revised Due Date";
export const REVISED_INTEREST = "Revised Interest";
export const LOAN_DEFER_FEE = "Loan Defer Fee";
export const BENEFITS_HEADING = "Benefits of Extending Due Date";
export const BENEFIT1 = "BENEFIT 2";
export const BENEFIT2 = "BENEFIT 1";
export const EMI = "EMI";
export const NO_OF_EMI = "No. Of EMI’s";
export const CONVERT_TO_EMI = "Convert to EMI";
export const MORATORIUM_TERMS_N_CONDITIONS = "Moratorium Terms & Conditions";
export const AGREE_TERMS = "I agree to the Terms & Conditions";
export const CHOOSE_PAYMENT = "Choose Payment Gateway to proceed";
export const CONVENIENCE_CHARGE =
  "*Service provider may charge a convenience fee";
export const PAY_AND_EXT = "PAY & EXTEND DUE DATE";

//emi success page
export const CONGRATS = "Congratulations";
export const LOAN_CONVERTED_TO = "Your loan has been converted to";
export const VIEW_LOAN_DETAILS = "VIEW LOAN DETAILS";

//pg success page
export const GOTO_PG_ON_SUCCESS = "Go to PG, on Success";
export const COME_BACK_TO_ANDROID = "come Back to Android";

export const MOBIKWIK_LOWERCASE = "mobikwik";
export const RAZOR_PAY_LOWERCASE = "razorpay";
export const GOOGLE_PAY_LOWERCASE = "googlepay";
export const MOBILE_NUMBER = "MobileNumber";
export const TOKEN = "_ta";
export const REFRESH_TOKEN = "_tr";
export const USER_NAME = "full_name";
export const USER_ID = "user_id";
export const USER_STATUS = "status";
export const USER_SELFIE = "selfie";
export const VERSION_NAME = "versionName";
export const BUILD_NUMBER = "buildNumber";
export const EXTDUE = "extdue";

export const OK = "OK";
export const REPAYMENT = "repayment";

export const MAX_LENGTH_COUPON = 15;
export const MIN_LENGTH_COUPON = 5;

export const RAZOR_PAY_CODE = "rp";
export const CASHFREE_PAY_CODE = "csfree";
export const UPI_CODE = "iciciupi";
export const PHONE_PE_CODE="phonepe"
