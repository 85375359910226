import React, { useEffect, useState } from "react";
import "../assets/scss/BoosterHeader.scss";
import Config from "../utils/i18n";
import { Strings } from "../utils/Strings";
import signout from "../assets/img/signout.svg";
import LogoAlone from "../assets/img/LoanfrontColLogo.svg";
import vaibhavLogo from "../assets/img/vaibhavLogo.svg";
Config(Strings);

export function MainHeader(props) {
  const [host, setHost] = useState("");
  useEffect(() => {
    setHost(sessionStorage.getItem("host"));
  }, []);

  return (
    <>
      <div className="booster_header_main_div">
        <img
          className={
            host === "vaibhav-vyapaar"
              ? "booster_header_logo_img_vvpl"
              : "booster_header_logo_img"
          }
          src={host === "vaibhav-vyapaar" ? vaibhavLogo : LogoAlone}
          alt="logo"
        />
        {props.target !== "payment" && (
          <div
            className={
              host === "vaibhav-vyapaar" ? "logoutbtnVvpl" : "logoutbtm"
            }
            onClick={() => props.onclick("login", "")}
          >
            <img width="20px" height="20px" src={signout} alt="signout" />
            SIGNOUT
          </div>
        )}
      </div>
    </>
  );
}

export default MainHeader;
