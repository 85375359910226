import {
    REPAYMENT_OPTIONS_START,
    REPAYMENT_OPTIONS_SUCCESS,
    REPAYMENT_OPTIONS_FAIL
} from "../utils/Types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case REPAYMENT_OPTIONS_START:
            return {
                ...state,
                ...action.payload,
                isLoading: true,
            };

        case REPAYMENT_OPTIONS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false,
            };

        case REPAYMENT_OPTIONS_FAIL:
            return {
                ...state,
                ...action.payload,
                isLoading: false,
            };

        default:
            return state;
    }
}
