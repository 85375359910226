import React, { useEffect } from "react";
import Verified from "../../assets/img/verified.svg";
import "./Success.scss";

function Success(props) {
  const { ChangeActiveComp } = props;

  const navigate = () => {
    ChangeActiveComp("activeLoan");
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("phonepeFinalSuccess");
      sessionStorage.removeItem("phonepeSuccess");
      sessionStorage.removeItem("phonepeFail");
    };
  }, []);

  return (
    <div className="loginMainDiv">
      <div className="loginSubDiv">
        <p className="welcomeTxt">Thank you!</p>
        <p className="nameText">
          {sessionStorage.getItem("panName") &&
            sessionStorage.getItem("panName")}
        </p>
        <img className="successImg" src={Verified} alt="Verified" />
        <p className="successMsg">Payment success</p>
        <p>
          We have received your payment of{" "}
          <b>
            <span>&#x20B9;</span>
            {sessionStorage.getItem("order_amount") &&
              sessionStorage.getItem("order_amount")}
          </b>
        </p>
        <button className="customBtn" onClick={navigate}>
          OK
        </button>
      </div>
    </div>
  );
}

export default Success;
