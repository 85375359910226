import React, { useState, useEffect } from "react";
import LogoAlone from "../../assets/img/LoanfrontColLogo.svg";
// import Sad from "../../assets/img/smily.svg";
import vaibhavLogo from "../../assets/img/vaibhavLogo.svg";
import backArrow from "../../assets/img/baseline_arrow.svg";
import HomePage from "../ProductScreen/HomePage";
import MainHeader from "../../reusableComponents/MainHeader";

function Download(props) {
  const { ChangeActiveComp } = props;
  const [host, setHost] = useState("");

  useEffect(() => {
    setHost(sessionStorage.getItem("host"));
  }, []);

  const goBack = () => {
    props.ChangeActiveComp("login");
    window.location.href =
      host === "vaibhav-vyapaar"
        ? "https://vaibhav-vyapaar.com/"
        : "https://loanfront.in/";
  };

  return (
    <>
      <div>
        <MainHeader onclick={ChangeActiveComp} />
        <div className="mt-5">
          <HomePage />
        </div>
      </div>
    </>
    // <div className="loginMainDiv" style={{ height: "90%" }}>
    //   <div className="loginSubDiv d-flex flex-column align-items-center">
    //     <img
    //       className="booster_header_logo_img"
    //       src={host === "vaibhav-vyapaar" ? vaibhavLogo : LogoAlone}
    //       alt="logo"
    //     />
    //     {/* <img className="booster_header_logo_img mt-4" src={Sad} alt="logo" /> */}
    //     <h3 className="mt-3" style={{ color: "#1d2a78" }}>
    //       No Active Loans Found!
    //     </h3>
    //     <p className="mt-4 mb-5" style={{ fontSize: "18px" }}>
    //       Currently you don't have any active loan, for taking loan please
    //       download the{" "}
    //       <a
    //         className="applink"
    //         target="_blank"
    //         href="https://play.google.com/store/apps/details?id=in.loanfront.android"
    //       >
    //         <strong style={{ color: "#1d2a78" }}>LoanFront App</strong>
    //       </a>{" "}
    //       from playstore
    //     </p>
    //     <div className="linkdiv">
    //       <img src={backArrow} />
    //       <a onClick={goBack}>Go back to Homepage</a>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Download;
