import i18n from "i18next";
import { initReactI18next } from "react-i18next";


const Config = (file) =>
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: file },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });

export default Config;