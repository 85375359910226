import React from "react";
import { Spinner } from "react-bootstrap";

function CustomLoader(props) {
  return (
    <>
      <button className={props.className} disabled>
        <Spinner
          className={
            props.className === "customBtnVvpl"
              ? "loaderSpinnervvpl"
              : "loaderSpinner"
          }
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span
          className={
            props.className === "customBtnVvpl"
              ? "visually-hidden loaderTextvvpl"
              : "visually-hidden loaderText"
          }
        >
          Loading...
        </span>
      </button>
    </>
  );
}

export default CustomLoader;
